// import { notification } from "antd";
import { useParams } from "react-router-dom";
// import { apiSport } from "src/api";
import SportCategories from "./components/sportCategories";

const SportDetail = () => {
  let { _id } = useParams();

  // const handleDelete = async () => {
  //   const result = await apiSport.delete(_id);

  //   if (result?.statusCode === 0) {
  //     notification.success({ message: "Амжилттай үүсгэлээ." });
  //   } else {
  //     notification.error({ message: result?.error });
  //   }
  // };

  return (
    <>
      <SportCategories _id={_id} />
    </>
  );
};

export default SportDetail;
