import { Button, Table } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import { apiEvent } from "src/api";
import { SteppeHeader } from "src/components";
import CreateEventModal from "./components/createEventModal";
// import AssignOrganizerModal from "./Assign";
import { Link } from "react-router-dom";
import moment from "moment";

const EventList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);

  const { data, isLoading, refetch } = useQuery("events", () => {
    return apiEvent.find();
  });

  // const categories = useQuery("categories", () => {
  //   return;
  // });

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Нэр",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Эхлэх хугацаа",
      key: "startAt",
      dataIndex: "startAt",
      render: (row: any, __: any, index: number) => {
        return moment(row).format("lll");
      },
    },
    {
      title: "Дуусах хугацаа",
      key: "endAt",
      dataIndex: "endAt",
      render: (row: any, __: any, index: number) => {
        return moment(row).format("lll");
      },
    },
    {
      title: "Эвентийн тэмцээн",
      key: "actions",
      render: (row: any, __: any, index: number) => {
        return (
          <Button>
            <Link to={`/regsys/events/${row["_id"]}/competitions`}>
              Тэмцээнүүд
            </Link>
          </Button>
        );
      },
    },
    {
      title: "Зохион байгуулагчид",
      key: "actions",
      render: (row: any, __: any, index: number) => {
        return (
          <div className="mx-auto">
            <Link to={`/regsys/events/${row._id}/assign`}>
              <Button
                onClick={() => {
                  setIsAssignModalVisible(true);
                }}
              >
                Сонгох
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      title: "Үйлдэл",
      key: "actions",
      render: (row: any, __: any, index: number) => {
        return (
          <Link to={`${row["_id"]}`}>
            <Button
              onClick={() => {
                // localStorage.setItem("activeEventId", row["_id"]);
                refetch();
              }}
            >
              Засварлах
            </Button>
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <SteppeHeader
        title="Эвент"
        onCreateClick={() => {
          setIsModalVisible(true);
        }}
      />
      <Table
        rowKey="_id"
        columns={columns}
        scroll={{ x: 1000 }}
        dataSource={data?.data}
        loading={isLoading}
        pagination={{
          total: data?.pagination?.total,
        }}
      />

      <CreateEventModal
        isVisible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          refetch();
        }}
      />
    </>
  );
};

export default EventList;
