import { Button, Col, notification, PageHeader, Row, Table } from "antd";
import { useState } from "react";
import { apiEvent } from "src/api";

const CompetitionCategories = (props) => {
  const { eventId, competitionId, sportId, categories, refetch } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onDelete = async (_id) => {
    const result = await apiEvent.removeCategory(eventId, competitionId, _id);

    if (result?.statusCode === 0) {
      notification.success({ message: "Амжилттай." });
      setIsModalVisible(false);
      refetch();
    } else {
      notification.error({ message: result?.error });
    }
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Нэр",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Үйлдэл",
      key: "actions",
      render: (row: any, _: any, index: number) => {
        return (
          <Button
            type="primary"
            danger
            onClick={() => {
              onDelete(row["_id"]);
            }}
          >
            Устгах
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <PageHeader title="Ангилал" />
        </Col>
        <Col>
          {/* <Link to={`/regsys/events/${eventId}/competitions`}>
            <Button
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              Буцах
            </Button>
          </Link> */}
        </Col>
      </Row>
      <Table
        rowKey="_id"
        columns={columns}
        dataSource={categories}
        // loading={isLoading || isRefetching}
        // pagination={{
        //     total: data?.pagination?.total,
        // }}
      />
    </>
  );
};

export default CompetitionCategories;
