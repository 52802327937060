import {
  Button,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Spin,
  Table,
} from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import apiOrganization from "src/api/apiOrganization";
import { SteppeHeader } from "src/components";
import CreateOrganizationModal from "./components/CreateOrganizationModal";
import { useForm } from "antd/es/form/Form";

const OrganizationList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data, isLoading, refetch } = useQuery(
    "organizations",
    () => {
      return apiOrganization.find();
    }
  );

  const onCreate = async (values) => {
    const result = await apiOrganization.create(values);

    if (result?.statusCode === 0) {
      notification.success({ message: "Амжилттай үүсгэлээ." });
      setIsModalVisible(false);
      refetch();
    } else {
      notification.error({ message: result?.error });
    }
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Нэр",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Үйлдэл",
      key: "actions",
      render: (row: any, __: any, index: number) => {
        return (
          <>
            <Row align="middle">
              <Link
                key={`details-${row["_id"]}`}
                to={`/organizations/${row["_id"]}`}
              >
                <Button>Дэлгэрэнгүй</Button>
              </Link>

              <Popconfirm
                title="Устгахдаа итгэлтэй байна уу?"
                onConfirm={async () => {
                  const result = await apiOrganization.deleteOrganization(
                    row["_id"]
                  );

                  if (result?.statusCode === 0) {
                    notification.success({ message: "Амжилттай устгалаа." });
                    refetch();
                  } else {
                    notification.error({ message: result?.error });
                  }
                }}
                cancelText="Цуцлах"
                okText="Устгах"
                okButtonProps={{ danger: true }}
              >
                <Button style={{ marginLeft: 30 }} type="primary" danger>
                  Устгах
                </Button>
              </Popconfirm>
            </Row>
          </>
        );
      },
    },
  ];

  const [form] = useForm();

  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const onFinishName = async (values) => {
    setLoading(true);
    const result = await apiOrganization.searchOrganization(values.name);

    if (result?.status === "success") {
      setResult(result);
      setLoading(false);
    } else if (result?.message === "No invoice found.") {
      notification.error({ message: "Байгууллага олдсонгүй" });
    }
  };

  return (
    <>
      <PageHeader title={"Байгууллага хайх"} />
      <Form
        name="Search with name"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinishName}
        style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto" }}
      >
        <Row style={{ gap: 20 }}>
          <Form.Item name="name" style={{ width: 300 }}>
            <Input placeholder="Нэрээр хайх" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Хайх
            </Button>
          </Form.Item>
        </Row>
      </Form>

      {loading === true ? (
        <Row justify="center">
          <Spin />
        </Row>
      ) : (
        result !== null && (
          <>
            <PageHeader title={"Хайлтын илэрц"} />
            <Table rowKey="_id" columns={columns} dataSource={result?.data} />
          </>
        )
      )}

      <SteppeHeader
        title="Байгууллагын жагсаалт"
        onCreateClick={() => {
          setIsModalVisible(true);
        }}
      />
      <Table
        rowKey="_id"
        columns={columns}
        scroll={{ x: 600 }}
        dataSource={data?.data}
        loading={isLoading}
        pagination={{
          total: data?.pagination?.total,
        }}
      />
      <CreateOrganizationModal
        isVisible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        onSubmit={onCreate}
      />
    </>
  );
};

export default OrganizationList;
