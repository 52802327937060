import { Button, Col, PageHeader, Row } from "antd";

const SteppeHeader = (props) => {
  const { title, onCreateClick, disabled } = props;

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <PageHeader title={title} />
      </Col>
      {onCreateClick && (
        <Col>
          <Button disabled={disabled} type="primary" onClick={onCreateClick}>
            Шинээр үүсгэх
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default SteppeHeader;
