import { Button, Form, Input, Modal, Upload, message } from "antd";
import { useForm } from "antd/es/form/Form";
import type { RcFile } from "antd/es/upload/interface";
import { useState } from "react";
import { apiEvent } from "src/api";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";

const CreateSportFederationModal = (props) => {
  const { isVisible, onCancel, onSubmit } = props;

  const onFinish = async (values) => {
    onSubmit(values);
  };

  const [file1, setFile1] = useState(false);

  const [form] = useForm();

  const isImage = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const FILE_SIZE = 2 * 1024 * 1024;
    if (file.size > FILE_SIZE) {
      message.error("Зургийн хэмжээ 2мегабайт-с ихгүй байх шаардлагатай!");
    }
    if (!isJpgOrPng) {
      message.error("Та зөвхөн JPG/PNG форматтай зураг оруулах боломжтой!");
    }
    setFile1(isJpgOrPng);
    return !isJpgOrPng;
  };

  const handleImage = async (e: any) => {
    setLoading(true);
    let result: any;
    result = await apiEvent.apiUpload(e.file, "image");
    // setImageUrl("hehehe.com");
    // const bro = document.getElementById("imageUrl") as HTMLInputElement;
    if (result.success === true) {
      setLoading(false);
      e.file = result.data;
      form.setFieldsValue({
        logoUrl: result.data,
      });
    } else {
      setLoading(false);
      console.error("error: ", result.e);
    }
  };

  const [loading, setLoading] = useState(false);

  return (
    <Modal
      title="Холбоо үүсгэх"
      key={"create_modal"}
      footer={false}
      visible={isVisible}
      onCancel={onCancel}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Нэр"
          name="name"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="logoUrl" hidden={true}>
          {<Input hidden={false} id="imageUrl" />}
        </Form.Item>

        <Form.Item label="Арын зураг" required={true}>
          <Upload
            maxCount={1}
            listType="picture"
            beforeUpload={isImage}
            onChange={handleImage}
          >
            <Button>
              {" "}
              {loading ? (
                <LoadingOutlined
                  style={{
                    fontSize: 15,
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                  spin
                />
              ) : (
                <UploadOutlined />
              )}
              Файл оруулах
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            loading={loading}
            disabled={!file1}
            type="primary"
            htmlType="submit"
          >
            Үүсгэх
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateSportFederationModal;
