import axiosClient from "src/libs/axiosClient";
import api from "./baseApi";

const apiPost = {
  createPost: async (data: any) => {
    try {
      const res = await axiosClient.post("/admin/posts", data);
      return res.data;
    } catch (e) {
      return { success: false, e };
    }
  },
  getPost: async (id: any) => {
    try {
      const res = await axiosClient.get(`/admin/posts/${id}`);
      return res.data;
    } catch (e) {
      return { success: false, e };
    }
  },
  getPosts: async () => {
    try {
      const res = await axiosClient.get("/admin/posts");
      return res.data;
    } catch (e) {
      return { success: false, e };
    }
  },
  deletePost: async (id: any) => {
    try {
      const res = await axiosClient.delete(`/admin/posts/${id}`);
      return res.data;
    } catch (e) {
      return { success: false, e };
    }
  },
  updatePost: async (id: any, data: any) => {
    try {
      const res = await axiosClient.patch(`/admin/posts/${id}`, data);
      return res.data;
    } catch (e) {
      return { success: false, e };
    }
  },
};

export default apiPost;
