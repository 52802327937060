import { useState, lazy } from "react";
import {
  Button,
  Form,
  Input,
  Spin,
  Upload,
  message,
  notification,
  Row,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { UploadOutlined } from "@ant-design/icons";
import type { RcFile } from "antd/es/upload/interface";
import { useForm } from "antd/lib/form/Form";
import { apiEvent } from "src/api";
import apiPost from "src/api/post";
import { SteppeHeader } from "src/components";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
const EditorDynamic = lazy(() => import("./editor"));

const UpdateNews = (props) => {
  const [editor, setEditor] = useState<any>("");
  const [editorData, setEditorData] = useState<any>(null);
  const [change, setChange] = useState(false);
  const [file1, setFile1] = useState(false);
  const navigate = useNavigate();
  const [form] = useForm();

  const { data, isLoading } = useQuery("post", () => {
    const id = window?.location?.pathname.split("/")[2];
    return apiPost.getPost(id);
  });
  const updatePost = async (id: any, data: any, values: any) => {
    const tempValues = { ...values, text: data };
    // console.log("tempValues: ", tempValues);
    const result = await apiPost.updatePost(id, tempValues);

    if (result?.statusCode === 0) {
      notification.success({ message: "Амжилттай шинэчлэгдлээ." });
      navigate("/news");
      setChange(false);
    } else {
      notification.error({ message: result?.error });
    }
  };
  const onFinish = async (values) => {
    const id = window?.location?.pathname.split("/")[2];
    editor
      .save()
      .then((data) => {
        updatePost(id, data, values);
      })
      .catch((error) => {
        console.log("Saving failed: ", error);
      });
  };
  const handleImage = async (e: any) => {
    setChange(true);
    let result: any;
    result = await apiEvent.apiUpload(e.file, "image");
    if (result.success === true) {
      e.file = result.data;

      form.setFieldsValue({
        imageUrl: result.data,
      });
    } else {
      console.error("error: ", result.e);
    }
  };
  const isImage = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Та зөвхөн JPG/PNG форматтай зураг оруулах боломжтой!");
    }
    setFile1(isJpgOrPng);
    return !isJpgOrPng;
  };
  return (
    <>
      <Link to={`/news`}>
        <Button>Буцах</Button>
      </Link>
      <SteppeHeader title="Мэдээ шинэчлэх" />

      {isLoading ? (
        <Row justify="center" align="middle">
          <Spin />
        </Row>
      ) : (
        <Form
          layout={"vertical"}
          form={form}
          name="basic"
          onChange={() => setChange(true)}
          labelCol={{ span: 6 }}
          style={{ paddingLeft: 24 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ ...data?.data, remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Гарчиг"
            name="name"
            rules={[
              {
                required: true,
                message: "Заавал бөглөнө үү.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Богино мэдээ"
            name="shortDescription"
            rules={[
              {
                required: true,
                message: "Заавал бөглөнө үү.",
              },
            ]}
          >
            <TextArea style={{ height: 200 }} />
          </Form.Item>
          <Form.Item name="imageUrl" hidden={true}>
            {<Input hidden={true} id="imageUrl" />}
          </Form.Item>
          <Form.Item label="Арын зураг">
            <Upload
              maxCount={1}
              listType="picture"
              beforeUpload={isImage}
              onChange={handleImage}
              defaultFileList={
                data?.data?.imageUrl && [
                  {
                    uid: "-1",
                    name: `${data?.data.imageUrl.split("/image/")[1]}`,
                    status: "done",
                    url: data?.data?.imageUrl,
                  },
                ]
              }
            >
              <Button icon={<UploadOutlined />}>Зураг оруулах</Button>
            </Upload>
          </Form.Item>
          <div>
            <p>Дэлгэрэнгүй мэдээ</p>
            <div
              style={{
                maxWidth: "800px",
                minHeight: "300px",
                border: "1px solid rgba(5, 5, 5, 0.06)",
                padding: "30px 10px",
              }}
            >
              <EditorDynamic
                setChange={setChange}
                data={data?.data.text}
                editor={editor}
                setEditor={setEditor}
              />
            </div>
          </div>
          <Form.Item wrapperCol={{ offset: 21, span: 20 }}>
            <Button type="primary" disabled={!change} htmlType="submit">
              Хадгалах
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default UpdateNews;
