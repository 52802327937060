import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  SelectProps,
  Spin,
  message,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import apiOrganizer from "src/api/apiOrganizer";
import { useQuery } from "react-query";
import { apiEvent, apiSport } from "src/api";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
import Upload, { RcFile } from "antd/lib/upload";

const SportFederationDetail = (props: any) => {
  const { data, onCancel, isVisible, form, refetchList } = props;
  let number = data?.user?.metadata?.registrationNumber.split("");
  number?.shift();
  number?.shift();

  useEffect(() => {
    form.setFieldsValue({ s: registration });
  });

  number = number.join("");

  const [change, setChange] = useState(false);
  const [letterF, setLetterF] = useState(
    data?.user?.metadata?.registrationNumber?.split("")[0]
  );
  const [letterS, setLetterS] = useState(
    data?.user?.metadata?.registrationNumber?.split("")[1]
  );
  const [registration, setRegistration] = useState(number);
  const [loading, setLoading] = useState(false);

  let sports = data?.sportsObj?.map((e) => {
    return e._id;
  });

  const sportsList = useQuery("sports", () => {
    return apiSport.find();
  });

  const options: SelectProps["options"] = [];

  if (sportsList) {
    sportsList?.data?.data?.map((e) => {
      options.push({ label: e.name, value: e._id });
    });
  }

  const onFinish = async (values) => {
    if (values.avatarUrl === undefined || values.avatarUrl === "") {
      notification.error({ message: "Tа зургаа заавал оруулах шаардлагатай" });
      return;
    }
    let tmp = letterF + letterS + registration;
    if (tmp.length !== 0) {
      values.registrationNumber = tmp;
    }

    const result = await apiOrganizer.updateOrganizer(data.user._id, values);

    if (result?.status === "success") {
      setLoading(false);
      notification.success({ message: "Амжилттай хадгаллаа." });
      refetchList();
      onCancel(true);
    } else {
      setLoading(false);
      notification.error({ message: result?.error });
    }
  };

  const onChangeFL = (value) => {
    setLetterF(value);
  };

  const onChangeSL = (value) => {
    setLetterS(value);
  };

  const onChangeNumber = (value) => {
    setRegistration(value.target.value);
  };

  const onChange = () => {
    form.setFieldsValue({
      username: form.getFieldValue("email"),
    });
  };

  const dict = "абвгдеёжзийклмноөпрстуүфхцчшщъыьэюя".toUpperCase().split("");

  const isImage = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const FILE_SIZE = 2 * 1024 * 1024;
    if (file.size > FILE_SIZE) {
      message.error("Зургийн хэмжээ 2мегабайт-с ихгүй байх шаардлагатай!");
    }

    if (!isJpgOrPng) {
      message.error("Та зөвхөн JPG/PNG форматтай зураг оруулах боломжтой!");
    }
    return !isJpgOrPng;
  };

  const handleImage = async (e: any) => {
    if (e.fileList.length === 0) {
      form.setFieldsValue({
        avatarUrl: "",
      });
      setLoading(false);
      // setFile1(false);
    } else {
      setLoading(true);
      let result: any;
      result = await apiEvent.apiUpload(e.file, "image");

      if (result.success === true) {
        e.file = result.data;
        form.setFieldsValue({
          avatarUrl: result.data,
        });
        setLoading(false);
      } else {
        // setLoading(false);
        console.error("error: ", result.e);
      }
    }
  };

  return (
    <>
      <Modal
        title="Дэлгэрэнгүй мэдээлэл"
        key={"create_modal"}
        footer={false}
        visible={isVisible}
        onCancel={onCancel}
      >
        {data !== null ? (
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onChange={() => {
              setChange(true);
            }}
            initialValues={{
              remember: true,
              username: data?.user.metadata.email,
              firstName: data?.user.metadata.firstName,
              lastName: data?.user.metadata.lastName,
              registrationNumber: data?.user.metadata.registrationNumber,
              email: data?.user.metadata.email,
              birthday: moment(data?.user?.metadata?.birthday),
              gender: data?.user.metadata.gender,
              phoneNumber: data?.user.metadata.phoneNumber,
              organization: data?.user.metadata.organization,
              avatarUrl: data?.user.metadata.avatarUrl,
              sports: sports,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item label="Цээж зураг">
              <Upload
                listType="picture"
                onChange={handleImage}
                beforeUpload={isImage}
                defaultFileList={
                  data?.user.metadata.avatarUrl && [
                    {
                      uid: "-1",
                      name: "Profile.png",
                      status: "done",
                      url: data?.user.metadata.avatarUrl,
                    },
                  ]
                }
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Зураг оруулах</Button>
              </Upload>
            </Form.Item>

            <Form.Item name="avatarUrl" hidden={true}>
              {<Input hidden={false} />}
            </Form.Item>

            <Form.Item label="Нэвтрэх нэр" hidden={true} name="username">
              <Input />
            </Form.Item>

            <Form.Item label="Овог, нэр" style={{ marginBottom: 0 }}>
              <Form.Item
                name="lastName"
                rules={[{ required: false }]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input placeholder="Овог" />
              </Form.Item>
              <Form.Item
                name="firstName"
                rules={[{ required: false }]}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
              >
                <Input placeholder="Нэр" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Хүйс"
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно үү.",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Хүйс"
                // onChange={handleChange}
              >
                <Select.Option key="0" value={0}>
                  Эрэгтэй
                </Select.Option>
                <Select.Option key="1" value={1}>
                  Эмэгтэй
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Утасны дугаар"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Заавал оруулна уу",
                },
              ]}
            >
              <Input type="number" placeholder="Утасны дугаар" />
            </Form.Item>

            <Form.Item
              label="Төрсөн өдөр"
              name="birthday"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно үү.",
                },
              ]}
            >
              <DatePicker format="YYYY-MM-DD" placeholder="Төрсөн өдөр" />
            </Form.Item>

            <Form.Item label="Регистрийн дугаар" className="mb-0">
              <Input.Group compact>
                <Select
                  value={data?.user?.metadata?.registrationNumber?.split("")[0]}
                  onChange={onChangeFL}
                  placeholder="A"
                >
                  {dict.map((e: any) => {
                    return (
                      <Select.Option key={e} value={e}>
                        {e}
                      </Select.Option>
                    );
                  })}
                </Select>
                <Select
                  value={data?.user?.metadata?.registrationNumber?.split("")[1]}
                  onChange={onChangeSL}
                  placeholder="A"
                >
                  {dict.map((e: any) => {
                    return (
                      <Select.Option key={e} value={e}>
                        {e}
                      </Select.Option>
                    );
                  })}
                </Select>

                <Form.Item name="s" style={{ marginBottom: 0 }}>
                  <Input
                    // style={{ width: "100%" }}
                    // defaultValue={number.join("")}
                    onChange={(value) => {
                      onChangeNumber(value);
                    }}
                    placeholder="12341234"
                    type="number"
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Form.Item
              hidden={true}
              style={{ marginBottom: 0 }}
              name="registrationNumber"
            >
              <Input
              // style={{ width: "100%" }}
              // defaultValue="12345678"
              // type="number"
              />
            </Form.Item>

            <Form.Item
              label="И-мэйл"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Хүчингүй и-мэйл байна!",
                  type: "email",
                },
              ]}
            >
              <Input onChange={onChange} placeholder="И-мэйл хаяг" />
            </Form.Item>

            <Form.Item label="Хариуцаж буй спорт" name="sports">
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Хариуцаж буй спорт"
                options={options}
              />
            </Form.Item>

            <Form.Item
              label="Харъяа байгууллага"
              name="organization"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно үү.",
                },
              ]}
            >
              <Input placeholder="Харъяа байгууллага" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button loading={loading} type="primary" htmlType="submit">
                Хадгалах
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Row justify="center" align="middle">
            <Spin />
          </Row>
        )}
      </Modal>
    </>
  );
};

export default SportFederationDetail;
