import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  SelectProps,
  Upload,
  message,
  notification,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { apiEvent } from "src/api";
import { useState } from "react";
import type { RcFile } from "antd/es/upload/interface";
import { useQuery } from "react-query";
import apiOrganizer from "src/api/apiOrganizer";

const CreateEventModal = (props) => {
  const { isVisible, onCancel } = props;

  //  --------------- File upload settings START
  const [file1, setFile1] = useState(false);
  const [file2, setFile2] = useState(false);
  const [file3, setFile3] = useState(false);

  // Check file type
  const isImage = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Та зөвхөн JPG/PNG форматтай зураг оруулах боломжтой!");
    }
    setFile1(isJpgOrPng);
    return !isJpgOrPng;
  };
  const isPDF = (file: RcFile) => {
    const isJpgOrPng = file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("Та зөвхөн PDF файл оруулах боломжтой!");
    }
    setFile2(isJpgOrPng);
    return !isJpgOrPng;
  };
  const isPDF1 = (file: RcFile) => {
    const isJpgOrPng = file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("Та зөвхөн PDF файл оруулах боломжтой!");
    }
    setFile3(isJpgOrPng);
    return !isJpgOrPng;
  };

  const handleImage = async (e: any) => {
    let result: any;
    result = await apiEvent.apiUpload(e.file, "image");
    // setImageUrl("hehehe.com");
    // const bro = document.getElementById("imageUrl") as HTMLInputElement;
    if (result.success === true) {
      e.file = result.data;
      form.setFieldsValue({
        logoUrl: result.data,
      });
    } else {
      console.error("error: ", result.e);
    }
  };
  const handlePdf = async (e: any) => {
    let result: any;
    result = await apiEvent.apiUpload(e.file, "pdf");

    if (result.success === true) {
      e.file = result.data;
      form.setFieldsValue({
        pdfUrl: result.data,
      });
    } else {
      console.error("error: ", result.e);
    }
  };
  const handleRulePdf = async (e: any) => {
    let result: any;
    result = await apiEvent.apiUpload(e.file, "pdf");

    if (result.success === true) {
      e.file = result.data;
      form.setFieldsValue({
        rulePdfUrl: result.data,
      });
      console.log(e);
    } else {
      console.error("error: ", result.e);
    }
  };

  //  --------------- File upload settings END

  // Form submission
  const onFinish = async (values) => {
    const result = await apiEvent.create(values);

    if (result?.statusCode === 0) {
      notification.success({ message: "Амжилттай үүсгэлээ." });
      onCancel(true);
    } else {
      notification.error({ message: result?.error });
    }
  };

  const onFinishFailed = (values) => {
    console.log("Fail", values);
  };

  const [form] = Form.useForm();

  const options: SelectProps["options"] = [];

  const { data, isLoading } = useQuery("organizers", () => {
    return apiOrganizer.find();
  });

  if (!isLoading) {
    data?.data.map((e) => {
      // console.log(e.user.);
      options.push({
        label:
          e.user.metadata.firstName +
          " " +
          e.user.metadata.lastName +
          " | (" +
          e.user.metadata.email +
          ")",
        value: e.user.metadata.email,
      });

      return null; // TODO wtf
    });
  }

  return (
    <Modal
      title="Эвент үүсгэх"
      key={"create_modal"}
      footer={false}
      visible={isVisible}
      onCancel={onCancel}
      width={800}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 6 }}
        // style={{ width: 800 }}
        wrapperCol={{ span: 22 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Эвентийн нэр"
          name="name"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Дэлгэрэнгүй"
          name="description"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно уу.",
            },
          ]}
        >
          <TextArea style={{ height: 200 }} />
        </Form.Item>

        <Form.Item
          name="location"
          label="Хаана болох"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно уу.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Зохион байгуулагч"
          name="organizers"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно үү.",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Хариуцаж буй ЗБ"
            options={options}
          />
        </Form.Item>

        <Form.Item
          name="mapLink"
          label="Google Maps link"
          extra="Жишээлбэл: https://www.steppelink.mn"
          rules={[
            {
              type: "url",
              required: true,
              message: "Зөв холбоос оруулна уу.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="logoUrl" hidden={true}>
          {<Input hidden={true} id="imageUrl" />}
        </Form.Item>
        <Form.Item label="Арын зураг">
          <Upload
            maxCount={1}
            listType="picture"
            beforeUpload={isImage}
            onChange={handleImage}
          >
            <Button icon={<UploadOutlined />}>Файл оруулах</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Эхлэх огноо"
          name="startAt"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно уу.",
            },
          ]}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{
              defaultValue: moment("00:00:00", "HH:mm:ss"),
            }}
          />
        </Form.Item>
        <Form.Item
          label="Дуусах хугацаа"
          name="endAt"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно уу.",
            },
          ]}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{
              defaultValue: moment("00:00:00", "HH:mm:ss"),
            }}
          />
        </Form.Item>

        <Form.Item name="pdfUrl" hidden={true}>
          {<Input hidden={false} />}
        </Form.Item>

        <Form.Item
          label="Удирдамж"
          rules={[
            {
              required: true,
              message: "Файл оруулна уу.",
            },
          ]}
        >
          <Upload maxCount={1} beforeUpload={isPDF} onChange={handlePdf}>
            <Button icon={<UploadOutlined />}>Файл оруулах</Button>
          </Upload>
        </Form.Item>

        <Form.Item name="rulePdfUrl" hidden={true}>
          {<Input hidden={false} />}
        </Form.Item>
        <Form.Item
          label="Дүрэм"
          rules={[
            {
              required: true,
              message: "Файл оруулна уу.",
            },
          ]}
        >
          <Upload maxCount={1} beforeUpload={isPDF1} onChange={handleRulePdf}>
            <Button icon={<UploadOutlined />}>Файл оруулах</Button>
          </Upload>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 21, span: 20 }}>
          <Button
            type="primary"
            disabled={!(file1 && file2 && file3)}
            htmlType="submit"
          >
            Үүсгэх
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateEventModal;
