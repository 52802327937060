import axiosClient from "src/libs/axiosClient";
import api from "./baseApi";

const updateOrganizer = async (data: any, eventId: string) => {
  const res = axiosClient.post(`admin/events/${eventId}/organizer`, data);

  return res;
};

const updatePress = async (id: string, data: any) => {
  const res = api(`admin/press/${id}`).update(data);

  return res;
};

const getOrganizer = (eventId: string, filter: any) => {
  return api(`/admin/organizers/?eventId=${eventId}`).get();
};

const getPress = () => {
  return api(`/admin/press`).get();
};
const services = {
  updateOrganizer,
  updatePress,
  getOrganizer,
  getPress,
};

const apiAssign = api("/admin/events", services);

export default apiAssign;
