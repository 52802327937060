import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
} from "antd";
import { useState } from "react";

const UpdateCategoryModal = (props: any) => {
  const { data, onCancel, onUpdate, isVisible, form } = props;

  const [selectedType, setSelectedType] = useState(String(data?.type));

  const [change, setChange] = useState(false);

  return (
    <>
      <Modal
        title="Дэлгэрэнгүй мэдээлэл"
        key={"create_modal"}
        footer={false}
        visible={isVisible}
        onCancel={onCancel}
      >
        {data !== null ? (
          <Form
            name="basic"
            form={form}
            onChange={() => {
              setChange(true);
            }}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
              remember: false,
              code: data?.code,
              name: data?.name,
              type: String(data?.type),
              participantsCountMin: data?.participantsCount?.min,
              participantsCountMax: data?.participantsCount?.max,
              gender: data?.gender,
              ageMin: data?.age?.min,
              ageMax: data?.age?.max,
            }}
            onFinish={onUpdate}
            style={{ maxWidth: 800 }}
            autoComplete="off"
          >
            <Form.Item
              label="Код"
              name="code"
              rules={[
                {
                  required: true,
                  message: "Заавал бөглөнө үү.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Нэр"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Заавал бөглөнө үү.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Төрөл"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно үү.",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                onChange={(value) => {
                  setSelectedType(value);
                }}
              >
                <Select.Option key="1" value="1">
                  Ганцаарчилсан
                </Select.Option>
                <Select.Option key="2" value="2">
                  Багийн
                </Select.Option>
              </Select>
            </Form.Item>

            {selectedType === "2" && (
              <>
                <Form.Item
                  label="Гишүүд : Доод"
                  name="participantsCountMin"
                  rules={[
                    {
                      required: true,
                      message: "Заавал сонгоно үү.",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={1} max={100} />
                </Form.Item>
                <Form.Item
                  label="Гишүүд : Дээд"
                  name="participantsCountMax"
                  rules={[
                    {
                      required: true,
                      message: "Заавал сонгоно үү.",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={1} max={100} />
                </Form.Item>
              </>
            )}

            <Form.Item
              label="Хүйс"
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно үү.",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                // onChange={handleChange}
              >
                <Select.Option key="0" value={0}>
                  Зөвхөн эрэгтэй
                </Select.Option>
                <Select.Option key="1" value={1}>
                  Зөвхөн эмэгтэй
                </Select.Option>
                <Select.Option key="2" value={2}>
                  Заавал холимог
                </Select.Option>
                <Select.Option key="99" value={99}>
                  Нээлттэй
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Нас : Доод"
              name="ageMin"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно үү.",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} min={1} max={100} />
            </Form.Item>

            <Form.Item
              label="Нас : Дээд"
              name="ageMax"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно үү.",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} min={1} max={100} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Хадгалах
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Row justify="center" align="middle">
            <Spin />
          </Row>
        )}
      </Modal>
    </>
  );
};

export default UpdateCategoryModal;
