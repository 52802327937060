import { createContext, useEffect, useState } from "react";
import apiUser from "src/api/apiUser";
// import apiAuth from "src/api/auth";

const AuthContext = createContext(null);

// const UserFetch = async () => {
//   const [loaded, setLoaded] = useState(false);
//   const [user, setUser] = useState(null);

//   const res = await apiUser.me();

//   console.log(res);
//   if (res.success) {
//     setUser(res.data);
//     setLoaded(true);
//   } else {
//     setUser(null);
//     localStorage.clear();
//   }
//   return res;
// };

export const AuthProvider = (props) => {
  const [user, setUser] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const login = async (user) => {
    // const res = await apiAuth.signIn(user.username, user.password);
    // if (res.success) {
    //     console.log("xx", res?.data);
    //     const { accessToken } = res?.data;
    //     if (accessToken && accessToken !== "") {
    //         localStorage.setItem("token", accessToken);
    //     }
    //     setUser(user);
    //     setUserData(user);
    // }
    // return res;

    const res = await apiUser.me();

    if (res.success) {
      setUser(res.data);
      setLoaded(true);
    } else {
      setUser(null);
      localStorage.clear();
    }
    return res;

    // return null;
  };

  const setToken = async (token) => {
    localStorage.setItem(
      "user",
      JSON.stringify({
        token,
      })
    );
    localStorage.setItem("token", JSON.stringify(token));
  };

  const logout = () => {
    setUser(null);
    setUserData(null);
  };

  const setUserData = (user) => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  };

  const getUserData = async () => {
    // const tmp = localStorage.getItem("user");

    const res = await apiUser.me();

    if (res.success) {
      setUser(res.data);
      setLoaded(true);
    } else {
      setUser(null);
      localStorage.clear();
    }
    return res;
    // return tmp ? JSON.parse(tmp) : null;
  };

  useEffect(() => {
    setUser(getUserData());
    setLoaded(true);
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, loaded, setToken }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
