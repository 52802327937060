import { PageHeader } from "antd";
let logo = require("../assets/logo.png");

const Home = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <img src={logo} alt="Logo" className="w-[300px] mb-[3rem]" />
      <PageHeader title="Админы Системд тавтай морилно уу :)" />
    </div>
  );
};

export default Home;
