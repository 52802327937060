import { useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import apiUser from "src/api/apiUser";
import { useAuth } from "src/hooks";

const Callback = () => {
  const [searchParams] = useSearchParams();

  const { setToken } = useAuth();

  const accessToken = searchParams.get("accessToken");

  // console.log("accessToken: ", accessToken);
  setToken(accessToken);

  return <Navigate to={"/"} />;
};

export default Callback;
