import { Callback, Home, SignIn } from "src/pages";
import OrganizationDetail from "src/pages/organizations/OrganizationDetail";
import OrganizationList from "src/pages/organizations/OrganizationList";
import CompetitionDetail from "src/pages/regsys/CompetitionDetail";
import EventDetail from "src/pages/regsys/EventDetail";
import EventAssign from "src/pages/regsys/events/Assign";
import UserDetail from "src/pages/organizations/components/UserDetail";
import CompetitionsPage from "src/pages/regsys/competitions";
import PaymentPage from "src/pages/regsys/Payment";
import Registrations from "src/pages/regsys/competitions/Registrations";
import { EventList } from "src/pages/regsys/events";
import { SportDetail, SportFederationsList, SportList } from "src/pages/sports";
import OrganizerPage from "src/pages/Organizer";
import JudgesPage from "src/pages/Judges";
import SportFederationDetail from "src/pages/sports/SportFederationDetail";
import News from "src/pages/news/newsList";
import CreatePost from "src/pages/news/createNews";
import UpdateNews from "src/pages/news/updateNews";
type IPathProps = Array<{
  key: string;
  path: string;
  component: Function;
  exact: boolean;
  public: boolean;
}>;

const sportRoutes: IPathProps = [
  {
    key: "sportFederationList",
    path: "/sports/federations",
    component: SportFederationsList,
    exact: true,
    public: false,
  },
  {
    key: "sportFederationDetail",
    path: "/sports/federations/:_id",
    component: SportFederationDetail,
    exact: true,
    public: false,
  },
  {
    key: "sportDetail",
    path: "/sports/:_id",
    component: SportDetail,
    exact: true,
    public: false,
  },
  {
    key: "sportList",
    path: "/sports",
    component: SportList,
    exact: true,
    public: false,
  },
];

const organizationRoutes: IPathProps = [
  {
    key: "organizationList",
    path: "/organizations",
    component: OrganizationList,
    exact: true,
    public: false,
  },
  {
    key: "organizationDetail",
    path: "/organizations/:_id",
    component: OrganizationDetail,
    exact: true,
    public: false,
  },
  {
    key: "organizationUserDetail",
    path: "/organizations/:_id/users/:userId",
    component: UserDetail,
    exact: true,
    public: false,
  },
];

export const routesJudge: IPathProps = [
  {
    key: "judges",
    path: "/regsys/judges",
    component: JudgesPage,
    exact: true,
    public: false,
  },
  {
    key: "/",
    path: "/",
    component: Home,
    exact: true,
    public: false,
  },
];

export const routesAdmin: IPathProps = [
  {
    key: "judges",
    path: "/regsys/judges",
    component: JudgesPage,
    exact: true,
    public: false,
  },
  {
    key: "organizer",
    path: "/regsys/organizers",
    component: OrganizerPage,
    exact: true,
    public: false,
  },
  {
    key: "payment",
    path: "/regsys/payment",
    component: PaymentPage,
    exact: true,
    public: false,
  },
  {
    key: "eventList",
    path: "/regsys/events",
    component: EventList,
    exact: true,
    public: false,
  },
  {
    key: "eventListDetail",
    path: "/regsys/events/:eventId",
    component: EventDetail,
    exact: true,
    public: false,
  },
  {
    key: "eventAssign",
    path: "/regsys/events/:eventId/assign",
    component: EventAssign,
    exact: true,
    public: false,
  },
  {
    key: "regsysCompetitions",
    path: "/regsys/events/:eventId/competitions",
    component: CompetitionsPage,
    exact: true,
    public: false,
  },
  {
    key: "competitionDetail",
    path: "/regsys/events/:eventId/competitions/:competitionId",
    component: CompetitionDetail,
    exact: true,
    public: false,
  },
  {
    key: "competitionDetail",
    path: "/regsys/events/:eventId/competitions/:competitionId/registrations",
    component: Registrations,
    exact: true,
    public: false,
  },
  {
    key: "/",
    path: "/",
    component: Home,
    exact: true,
    public: false,
  },
  {
    key: "signin",
    path: "/signin",
    component: SignIn,
    exact: true,
    public: true,
  },
  {
    key: "callback",
    path: "/callback",
    component: Callback,
    exact: true,
    public: true,
  },
  {
    key: "news",
    path: "/news",
    component: News,
    exact: true,
    public: false,
  },
  {
    key: "news",
    path: "/news/create",
    component: CreatePost,
    exact: true,
    public: false,
  },
  {
    key: "news",
    path: "/news/:newsId",
    component: UpdateNews,
    exact: true,
    public: false,
  },
  ...sportRoutes,
  ...organizationRoutes,
  // ...managerRoutes,
];
