import { Button, Form, Input, Modal } from "antd";

const CreateOrganizationModal = (props) => {
  const { isVisible, onCancel, onSubmit } = props;

  const onFinish = async (values) => {
    onSubmit(values);
  };

  return (
    <Modal
      title="Байгууллага үүсгэх"
      key={"create_modal"}
      footer={false}
      visible={isVisible}
      onCancel={onCancel}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Нэр"
          name="name"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Үүсгэх
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateOrganizationModal;
