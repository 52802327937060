import {
  Button,
  Card,
  Col,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Spin,
  Table,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { apiEvent, apiSport } from "src/api";
import type { ColumnsType } from "antd/es/table/interface";

const CompetitionsPage = (props: any) => {
  const { eventId, competitionId } = useParams();

  const { data, isLoading } = useQuery(
    ["users", eventId, competitionId],
    () => {
      return apiEvent.getUsers(eventId, competitionId);
    }
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeRow, setActiveRow] = useState<any>();
  useEffect(() => {}, [activeRow]);

  const athlete =
    data?.data?.length > 0 && data?.data[0]?.athlete !== undefined
      ? data?.data[0]?.athlete?.sport?.sport
      : data?.data[0]?.teamMembers[0]?.sport?.sport;

  // console.log(data?.data[0]?.athlete);

  // Sort with categories
  const sportCategories = useQuery(
    ["sport-categories", eventId, competitionId, athlete],
    () => {
      return apiSport.getCategories(athlete);
    }
  );

  const filters = sportCategories?.data?.data.map((e: any) => {
    return { text: e?.name, value: e?._id };
  });

  const columns: ColumnsType = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Нэр",
      key: "name",
      render: (row: any) => {
        if (row.athlete) {
          return row?.athlete.lastName[0] + ". " + row?.athlete.firstName;
        } else {
          return row?.team[0]?.name;
        }
      },
    },
    {
      title: "Харъяалагдаж буй байгууллага",
      key: "categories",
      dataIndex: ["organization", 0],
      render: (row: any) => {
        return !row?.manualCreated ? "Байхгүй" : row?.name;
      },
    },
    {
      title: "Харъяалагдаж буй ангиллууд",
      key: "categories",
      dataIndex: ["categories"],
      filters: filters,
      // filteredValue: filteredInfo.name || null,
      onFilter: (value: string, record: any) => {
        return value === record.categories._id;
      },

      render: (row: any) => {
        return (
          <div>
            {row.map((e) => {
              return <Button key={e._id}>{e.name}</Button>;
            })}
          </div>
        );
      },
    },
    {
      title: "Хариуцаж буй дасгалжуулагчид",
      key: "coach",
      dataIndex: ["coach"],
      render: (row: any) => {
        return (
          <div>
            {row.length !== 0 &&
              row?.map((e) => {
                if (e) {
                  return e?.lastName[0] + ". " + e?.firstName + " ";
                } else {
                  return "Байхгүй";
                }
              })}
          </div>
        );
      },
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (row: any, __: any, index: number) => {
        return (
          <Row align="middle" className="min-w-max">
            <Col style={{ marginRight: 20 }}>
              <Button
                onClick={() => {
                  setActiveRow(row);
                  setIsModalVisible(true);
                }}
              >
                Дэлгэрэнгүй
              </Button>
            </Col>
          </Row>
        );
      },
    },
  ];
  // const columnsCoach: ColumnsType = [
  //   {
  //     title: "#",
  //     key: "index",
  //     render: (_: any, __: any, index: number) => {
  //       return `${index + 1}`;
  //     },
  //   },
  //   {
  //     title: "Нэр",
  //     key: "name",
  //     render: (row: any) => {
  //       if (row.athlete) {
  //         return row?.athlete.lastName[0] + ". " + row?.athlete.firstName;
  //       } else {
  //         return row?.team[0]?.name;
  //       }
  //     },
  //   },
  //   {
  //     title: "Харъяалагдаж буй байгууллага",
  //     key: "categories",
  //     dataIndex: ["organization", 0],
  //     render: (row: any) => {
  //       return !row?.manualCreated ? "Байхгүй" : row?.name;
  //     },
  //   },
  //   {
  //     title: "Хариуцаж байгаа тамирчин/баг",
  //     key: "inCharge",
  //     dataIndex: ["categoriesObjs"],
  //     filters: filters,
  //     // filteredValue: filteredInfo.name || null,
  //     onFilter: (value: string, record: any) => {
  //       return value === record.categoriesObjs[0]._id;
  //     },

  //     render: (row: any) => {
  //       return (
  //         <div>
  //           {row.map((e) => {
  //             return <Button key={e._id}>{e.name}</Button>;
  //           })}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: "Үйлдэл",
  //     key: "action",
  //     render: (row: any, __: any, index: number) => {
  //       return (
  //         <Row align="middle" className="min-w-max">
  //           <Col style={{ marginRight: 20 }}>
  //             <Button
  //               onClick={() => {
  //                 alert("work");
  //                 console.log("data: ", row);
  //                 // setActiveRow(row);
  //                 // setIsModalVisible(true);
  //               }}
  //             >
  //               Дэлгэрэнгүй
  //             </Button>
  //           </Col>
  //           <Col>
  //             <Button
  //               onClick={() => {
  //                 console.log("print");
  //               }}
  //               type="primary"
  //             >
  //               Mандат хэвлэх
  //             </Button>
  //           </Col>
  //         </Row>
  //       );
  //     },
  //   },
  // ];

  // console.log("data: ", data);

  // const [form] = useForm();
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  // const onFinishName = async (values) => {
  //   setLoading(true);
  //   const result = await apiEvent.checkUsers(values);

  //   if (result?.status === "success") {
  //     setResult(result);
  //     form.resetFields();
  //     setLoading(false);
  //   } else if (result?.message === "No invoice found.") {
  //     notification.error({ message: "Нэр олдсонгүй" });
  //   }
  // };

  const [selectedCoaches, setSelectedCoaches] = useState([]);
  const [selectedAthletes, setSelectedAthletes] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(() => {
    // console.log("athletes: ", selectedAthletes);
    // console.log("coaches: ", selectedAthletes);
  }, [selectedAthletes, selectedCoaches, pdfUrl]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      let tmpAthlete = selectedRows.map((e) => {
        return e._id;
      });

      let tmpCoach = [];

      selectedRows.map((e) => {
        e.coach.length > 0 && tmpCoach.push(e._id);
      });

      setSelectedAthletes(tmpAthlete);
      setSelectedCoaches(tmpCoach);
      // console.log(
      //   "selectedRowKey",
      //   selectedRowKeys.join(",").split(","),
      //   "selectedRows: ",
      //   selectedAthletes
      // );
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <>
      <Col>
        <Button
          onClick={() => {
            setActiveRow([]);
          }}
        >
          <Link to={`/regsys/events/${eventId}/competitions`}>Буцах</Link>
        </Button>
      </Col>
      <Row justify="space-between" align="middle">
        <Col>
          <PageHeader title={`Амжилттай төлөгдсөн тамирчдын жагсаалт`} />
        </Col>
      </Row>

      {/* <Form
        name="Search with mail"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinishName}
        style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto" }}
      >
        <Row style={{ gap: 20 }}>
          <Form.Item name="email" style={{ width: 300 }}>
            <Input placeholder="Төлөгчийн нэрээр хайх" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Хайх
            </Button>
          </Form.Item>
        </Row>
      </Form> */}

      {loading === true ? (
        <Row justify="center">
          <Spin />
        </Row>
      ) : (
        result !== null && (
          <>
            <PageHeader title={"Хайлтын илэрц"} />
            <Table
              rowKey="_id"
              columns={columns}
              // loading={isLoading || isRefetching}
              dataSource={result?.data}
              // pagination={{
              //   total: data?.page,
              // }}
            />
          </>
        )
      )}

      <Row
        align="middle"
        style={{ marginBottom: 20 }}
        // justify={"space-between"}
      >
        {/* <Col>
          <Button
            type="primary"
            onClick={print}
            disabled={!hasSelected}
            loading={loading}
          >
            Хэвлэх
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected
              ? `Нийт ${selectedRowKeys.length} хэрэглэгч сонгосон байна`
              : ""}
          </span>
        </Col> */}
        <Col style={{ marginRight: 20 }}>
          <a
            target="_blank"
            href={`${process.env.REACT_APP_API_BASE_URL}/admin/competitions/${competitionId}/entries/singles`}
            rel="noreferrer"
          >
            <Button type="primary">Тэмцээнд оролцогчдын Excel-г татах</Button>
          </a>
        </Col>
        <Col style={{ marginRight: 20 }}>
          <a
            target="_blank"
            href={`${process.env.REACT_APP_API_BASE_URL}/admin/competitions/${competitionId}/entries/teams`}
            rel="noreferrer"
          >
            <Button type="primary">Багийн гишүүдийн Excel-г татах</Button>
          </a>
        </Col>
        <Col style={{ marginRight: 20 }}>
          <Popconfirm
            title="Аль хэрэглэгчийн мандатыг хэвлэх вэ?"
            onConfirm={async () => {
              // Print athlete
              const tmp = { registrations: selectedAthletes };
              await apiEvent.printUser(competitionId, tmp).then((res) => {
                setPdfUrl(res.data);
                window.open(pdfUrl, "_blank");
              });
            }}
            onCancel={async () => {
              const tmp = { registrations: selectedCoaches };
              await apiEvent.printCoach(competitionId, tmp).then((res) => {
                setPdfUrl(res.data);
                window.open(pdfUrl, "_blank");
              });
            }}
            cancelText="Дасгалжуулагч"
            okText="Тамирчин"
            okButtonProps={{ danger: false }}
          >
            <Button type="primary">Mандат хэвлэх</Button>
          </Popconfirm>
        </Col>

        {pdfUrl !== "" && (
          <Col>
            <a target="_blank" href={pdfUrl} rel="noreferrer">
              <Button
                onClick={() => {
                  setPdfUrl("");
                }}
              >
                Mандатын PDF татах линк
              </Button>
            </a>
          </Col>
        )}
      </Row>

      <Table
        key={competitionId}
        rowKey="_id"
        columns={columns}
        rowSelection={{
          ...rowSelection,
        }}
        dataSource={data?.data}
        loading={isLoading}
        scroll={{ x: 1000 }}
        // onChange={handleChange}
        pagination={{
          total: data?.pagination?.total,
        }}
      />

      {/* <Row justify="space-between" align="middle">
        <Col>
          <PageHeader title={`Дасгалжуулагчид`} />
        </Col>
      </Row>

      <Form
        name="Search with coach name"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinishName}
        style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto" }}
      >
        <Row style={{ gap: 20 }}>
          <Form.Item name="email" style={{ width: 300 }}>
            <Input placeholder="Дасгалжуулагчийн нэрээр хайх" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Хайх
            </Button>
          </Form.Item>
        </Row>
      </Form> */}

      {/* <Table
        key="coach"
        rowKey="_id"
        columns={columnsCoach}
        dataSource={data?.data}
        loading={isLoading}
        scroll={{ x: 1000 }}
        // onChange={handleChange}
        rowSelection={rowSelection}
        pagination={{
          total: data?.pagination?.total,
        }}
      /> */}

      {activeRow?.athlete ? (
        <Modal
          visible={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          footer={false}
          width={600}
        >
          <Card
            title="Тамирчны дэлгэрэнгүй мэдээлэл"
            style={{
              width: 500,
              borderRadius: 10,
              marginTop: 30,
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <div className="grid grid-cols-2 mb-[2rem]">
              <div className="col-span-2 max-w-[120px] max-h-[150px] overflow-hidden mx-auto mb-[1rem]">
                <img
                  src={activeRow?.athlete?.avatarUrl}
                  alt="Logo"
                  className="mx-auto"
                />
              </div>
              <p className="font-[700] italic">Oвог, нэр</p>
              <p>
                {activeRow?.athlete?.lastName +
                  " " +
                  activeRow?.athlete?.firstName}
              </p>
              <p className="font-[700] italic">Төрсөн он:</p>
              <p>{moment(activeRow?.athlete?.birthday).format("l")}</p>
              <p className="font-[700] italic">Регистрийн дугаар:</p>
              <p>{activeRow?.athlete?.registrationNumber}</p>
              <p className="font-[700] italic">Хүйс:</p>
              <p>{activeRow?.athlete?.gender === 1 ? "Эмэгтэй" : "Эрэгтэй"}</p>
              <p className="font-[700] italic">Утасны дугаар:</p>
              <p>{activeRow?.athlete?.mobile?.value}</p>
              <p className="font-[700] italic">И-мэйл хаяг:</p>
              <p>{activeRow?.athlete?.email?.value}</p>
              <p className="font-[700] italic">Харъяа байгууллага:</p>
              <div className="flex flex-wrap">
                {activeRow?.organization &&
                  (activeRow?.organization?.manualCreated ? (
                    <p className="border-[1px] border-grey px-[5px] py-[2px] mr-[20px] rounded-lg">
                      {activeRow?.organization?.name}
                    </p>
                  ) : (
                    <p>Байхгүй</p>
                  ))}
              </div>
              <p className="font-[700] italic">Оролчож буй ангиллууд:</p>
              <div className="flex flex-wrap">
                {activeRow?.categories?.map((e) => {
                  return (
                    <p className="border-[1px] border-grey px-[5px] py-[2px] mr-[20px] rounded-lg">
                      {e?.name}
                    </p>
                  );
                })}
              </div>
            </div>
          </Card>
        </Modal>
      ) : // <div></div>
      activeRow?.teamMembers.length > 0 ? (
        // <div></div>
        <Modal
          visible={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          width={600}
        >
          <Card
            title="Тамирчдын дэлгэрэнгүй мэдээлэл"
            style={{
              width: 500,
              borderRadius: 10,
              marginTop: 30,
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <div>
              {activeRow?.teamMembers.map((e) => {
                return (
                  <div className="grid grid-cols-2 mb-[2rem]">
                    <div className="col-span-2 max-w-[120px] max-h-[150px] overflow-hidden mx-auto mb-[1rem]">
                      <img src={e.avatarUrl} alt="Logo" className="mx-auto" />
                    </div>
                    <p className="font-[700] italic">Oвог, нэр</p>
                    <p>{e.lastName + " " + e.firstName}</p>
                    <p className="font-[700] italic">Төрсөн он:</p>
                    <p>{moment(e.birthday).format("l")}</p>
                    <p className="font-[700] italic">Регистрийн дугаар:</p>
                    <p>{e.registrationNumber}</p>
                    <p className="font-[700] italic">Хүйс:</p>
                    <p>{e.gender === 1 ? "Эмэгтэй" : "Эрэгтэй"}</p>
                    <p className="font-[700] italic">Утасны дугаар:</p>
                    <p>{e.mobile?.value}</p>
                    <p className="font-[700] italic">И-мэйл хаяг:</p>
                    <p>{e.email?.value}</p>
                    <p className="font-[700] italic">Харъяа байгууллага:</p>
                    {/* <div className="flex flex-wrap">
                  {activeRow?.team.organization.map((e) => {
                    return e?.manualCreated ? (
                      <p className="border-[1px] border-grey px-[5px] py-[2px] mr-[20px] rounded-lg">
                        {e?.name}
                      </p>
                    ) : (
                      <p>Байхгүй</p>
                    );
                  })}
                </div>
                <p className="font-[700] italic">Оролчож буй ангиллууд:</p>
                <div className="flex flex-wrap">
                  {e?.team?.categoriesObjs?.map((e) => {
                    return (
                      <p className="border-[1px] border-grey px-[5px] py-[2px] mr-[20px] rounded-lg">
                        {e?.name}
                      </p>
                    );
                  })}
                </div> */}
                  </div>
                );
              })}
            </div>
          </Card>
        </Modal>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default CompetitionsPage;
