import api from "./baseApi";

const updateFederation = async (values: any, federationId: string) => {
  const r = await api(`/admin/sport-federations/${federationId}`)
    .update(values)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e;
    });

  return r;
};

const services = {
  updateFederation,
};

const apiSportFederation = api("/admin/sport-federations", services);

export default apiSportFederation;
