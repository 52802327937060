import { Button, Col, PageHeader, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import CreateJudgeModal from "./CreateJudgeModal";
import { useForm } from "antd/lib/form/Form";
import UpdateJudgeModal from "./UpdateJudgeModal";
import apiJudge from "src/api/apiJudge";

const JudgeList = () => {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);

  const [activeRow, setActiveRow] = useState<any[]>([]);

  const [form] = useForm();
  const [formCreate] = useForm();
  const handleRow = (e: any) => {
    setActiveRow(e);
  };
  useEffect(() => {
    form.resetFields();
  }, [activeRow, form]);

  const { data, isLoading, refetch } = useQuery("judge", () => {
    return apiJudge.find();
  });

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Овог, Нэр",
      key: "index",
      dataIndex: ["user", "metadata"],
      render: (row: any) => {
        return <div>{row?.lastName + " " + row?.firstName}</div>;
      },
    },
    {
      title: "И-мэйл",
      key: "email",
      dataIndex: ["user", "metadata", "email"],
    },
    {
      title: "Шүүж буй спорт",
      key: "index",
      dataIndex: ["sportsObj"],
      render: (row: any) => {
        return (
          <div className="flex gap-[10px] flex-wrap">
            {row?.map((e) => {
              return <Button key={e?.name}>{e?.name}</Button>;
            })}
          </div>
        );
      },
    },
    {
      title: "Үйлдэл",
      key: "index",
      render: (row: any, __: any, index: number) => {
        return (
          <>
            <Row align="middle" className="min-w-max">
              <Col className="mr-[1rem]">
                <Button
                  onClick={() => {
                    handleRow(row);
                    setUpdateModalVisible(true);
                  }}
                >
                  Дэлгэрэнгүй
                </Button>
              </Col>
              {/* <Col>
                <Popconfirm
                  title="Та тус зохион байгуулагчийг хасах гэж байна?"
                  onConfirm={async () => {
                    const result = await apiOrganizer.delete(row._id);
                    if (result?.acknowledged) {
                      notification.success({
                        message: "Амжилттай устгагдлаа.",
                      });
                    } else {
                      notification.error({ message: result?.error });
                    }
                    refetch();
                  }}
                  cancelText="Цуцлах"
                  okText="Устгах"
                  okButtonProps={{ danger: true }}
                >
                  <Button danger type="primary">
                    Устгах
                  </Button>
                </Popconfirm>
              </Col> */}
            </Row>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row align="middle" justify="space-between">
        <Col>
          <Row align="middle">
            <PageHeader title={"Шүүгчдийн жагсаалт"} />
          </Row>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setCreateModalVisible(true);
            }}
          >
            Шинээр үүсгэх
          </Button>
        </Col>
      </Row>

      <>
        <Table
          rowKey="_id"
          columns={columns}
          loading={isLoading}
          scroll={{ x: 1000 }}
          dataSource={data?.data}
          pagination={{
            total: data?.pagination?.total,
          }}
        />

        {/* {!isLoading && (
          <Row justify="center">
            <Pagination
              style={{ marginTop: 50 }}
              total={Math.ceil(data?.data?.total)}
              showSizeChanger
              showTotal={(total) => (
                <div style={{ alignItems: "middle", display: "flex" }}>
                  {`Нийт ${total} төлбөр`}
                </div>
              )}
              onChange={(page, pageSize) => {
                setLimit(pageSize);
                setOffset(page);
                refetch();
              }}
            />
          </Row>
        )} */}

        <CreateJudgeModal
          isVisible={createModalVisible}
          form={formCreate}
          refetchList={refetch}
          onCancel={() => {
            setCreateModalVisible(false);
            // formCreate.resetFields();
          }}
        />

        {updateModalVisible && (
          <UpdateJudgeModal
            data={activeRow}
            form={form}
            refetchList={refetch}
            isVisible={updateModalVisible}
            onCancel={() => {
              setUpdateModalVisible(false);
              form.resetFields();
            }}
          />
        )}
      </>
    </>
  );
};

export default JudgeList;
