import axiosClient from "src/libs/axiosClient";
import api from "./baseApi";

const changePayment = (friendlyNumber: string, filter: any) => {
  return api(`/admin/manual-payment/dondogdulam1512/${friendlyNumber}`).find(
    filter
  );
};

const getPayment = async (limit: number, offset: number, params: any = {}) => {
  const p2 = `/admin/payment/invoices?page=${offset}&limit=${limit}`;

  const res = await axiosClient.get(
    p2,
    Object.keys(params).length === 0 ? {} : { params }
  );

  if (res.data?.statusCode !== 0) {
    throw Error(res.data?.message);
  }

  return res.data;
};

const checkPayment = async (data: any) => {
  const res = await axiosClient.post(`/admin/payment/check`, data);

  return res.data;
};

const checkFriendlyNumber = async (data: any) => {
  const res = await axiosClient.post(`/admin/payment/check`, data);

  return res.data;
};

const services = {
  changePayment,
  checkPayment,
  checkFriendlyNumber,
  getPayment,
};

const apiPayment = api("/admin/payment/check", services);

export default apiPayment;
