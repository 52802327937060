import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Row,
  Spin,
  Upload,
  message,
  notification,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { SteppeHeader } from "src/components";
import moment from "moment";
import { apiEvent } from "src/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useState } from "react";
import type { RcFile } from "antd/es/upload/interface";

const EventDetail = () => {
  const { eventId } = useParams();

  const [file1, setFile1] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data, refetch } = useQuery("event-detail", async () => {
    return await apiEvent.find();
  });
  //  --------------- File upload settings START

  const isImage = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Та зөвхөн JPG/PNG форматтай зураг оруулах боломжтой!");
    }
    return !isJpgOrPng;
  };
  const isPDF = (file: RcFile) => {
    const isJpgOrPng = file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("Та зөвхөн PDF файл оруулах боломжтой!");
    }
    return !isJpgOrPng;
  };

  const handleImage = async (e: any) => {
    if (e.fileList.length === 0) {
      form.setFieldsValue({
        logoUrl: "",
      });
      setLoading(false);
      // setFile1(false);
    } else {
      setLoading(true);
      let result: any;
      result = await apiEvent.apiUpload(e.file, "image");

      if (result.success === true) {
        setFile1(true);
        e.file = result.data;
        form.setFieldsValue({
          logoUrl: result.data,
        });
        setLoading(false);
      } else {
        console.error("error: ", result.e);
      }
    }
  };

  const handlePdf = async (e: any) => {
    setChange(true);
    if (e.fileList.length === 0) {
      form.setFieldsValue({
        pdfUrl: "",
      });
    } else {
      setLoading(true);
      let result: any;
      result = await apiEvent.apiUpload(e.file, "pdf");

      if (result.success === true) {
        e.file = result.data;
        form.setFieldsValue({
          pdfUrl: result.data,
        });
        setLoading(false);
      } else {
        console.error("error: ", result.e);
      }
    }
  };
  const handleRulePdf = async (e: any) => {
    setChange(true);
    if (e.fileList.length === 0) {
      form.setFieldsValue({
        rulePdfUrl: "",
      });
    } else {
      setLoading(true);
      let result: any;
      result = await apiEvent.apiUpload(e.file, "pdf");

      if (result.success === true) {
        e.file = result.data;
        form.setFieldsValue({
          rulePdfUrl: result.data,
        });
        setLoading(false);
      } else {
        console.error("error: ", result.e);
      }
    }
  };

  var event: any;

  data &&
    data?.data.map((e: any) => {
      if (e._id === eventId) {
        event = e;
      }

      return null; // TODO fix
    });

  const handleDelete = async () => {
    const result = await apiEvent.deleteEvent(eventId);

    if (result?.statusCode === 0) {
      navigate("/regsys/events");
      notification.success({ message: "Амжилттай устгалаа." });
      refetch();
    } else {
      notification.error({ message: result?.error });
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const result = await apiEvent.updateEvent(values, eventId);
    console.log("Success:", result);

    // If result return status "1" => message Successfull
    if (result?.status === 1) {
      notification.success({ message: "Амжилттай хадгаллаа." });
      setLoading(false);
      setChange(false);
      refetch();
    } else {
      notification.error({ message: result?.error });
      setLoading(false);
    }
  };

  const [change, setChange] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  return (
    <>
      {(event && (
        <>
          <Link to={`/regsys/events`}>
            <Button style={{ display: "flex", alignItems: "center" }}>
              Буцах
            </Button>
          </Link>
          <Row
            // justify="space-between"
            align="middle"
            style={{ marginBottom: 50 }}
          >
            <Col>
              <SteppeHeader title={`${event?.name}`} />
            </Col>
            <Col></Col>
          </Row>
          <Form
            form={form}
            name="basic"
            onChange={() => {
              setChange(true);
            }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 22 }}
            initialValues={{
              remember: true,
              name: event?.name,
              description: event?.description,
              location: event?.location,
              mapLink: event?.mapLink,
              logoUrl: event?.assets?.logoUrl,
              pdfUrl: event?.pdfUrl,
              rulePdfUrl: event?.rulePdfUrl,
              fee: event?.fee,
              startAt: moment(event?.startAt),
              endAt: moment(event?.endAt),
              // status: 0,
            }}
            style={{ maxWidth: 800, marginLeft: "auto", marginRight: "auto" }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Эвентийн нэр"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Заавал бөглөнө үү.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            {/* <Form.Item name="status" hidden={true}>
              <Input />
            </Form.Item> */}

            <Form.Item
              label="Дэлгэрэнгүй"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно уу.",
                },
              ]}
            >
              <TextArea style={{ height: 200 }} />
            </Form.Item>

            <Form.Item
              name="location"
              label="Хаана болох"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно уу.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="mapLink"
              label="Google Maps link"
              extra="Жишээлбэл: https://www.steppelink.mn"
              rules={[
                {
                  type: "url",
                  required: true,
                  message: "Зөв холбоос оруулна уу.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="logoUrl" hidden={true}>
              {<Input hidden={false} />}
            </Form.Item>
            <Form.Item label="Арын зураг">
              <Upload
                listType="picture"
                onChange={handleImage}
                beforeUpload={isImage}
                defaultFileList={
                  event?.assets?.logoUrl && [
                    {
                      uid: "-1",
                      name: "Background.png",
                      status: "done",
                      url: event?.assets?.logoUrl,
                    },
                  ]
                }
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Зураг оруулах</Button>
              </Upload>
            </Form.Item>

            <Form.Item
              label="Эхлэх огноо"
              name="startAt"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно уу.",
                },
              ]}
            >
              <DatePicker format="YYYY/MM/DD HH:mm:ss" />
            </Form.Item>
            <Form.Item
              label="Дуусах хугацаа"
              name="endAt"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно уу.",
                },
              ]}
            >
              <DatePicker format="YYYY/MM/DD HH:mm:ss" />
            </Form.Item>

            <Form.Item name="pdfUrl" hidden={true}>
              {<Input hidden={false} />}
            </Form.Item>
            <Form.Item label="Удирдамж">
              <Upload
                onChange={handlePdf}
                beforeUpload={isPDF}
                defaultFileList={
                  event?.pdfUrl && [
                    {
                      uid: "-1",
                      name: "Udirdamj.pdf",
                      status: "done",
                      url: event?.pdfUrl,
                    },
                  ]
                }
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Файл оруулах</Button>
              </Upload>
            </Form.Item>

            <Form.Item name="rulePdfUrl" hidden={true}>
              {<Input hidden={false} />}
            </Form.Item>
            <Form.Item label="Дүрэм">
              <Upload
                onChange={handleRulePdf}
                beforeUpload={isPDF}
                defaultFileList={
                  event?.rulePdfUrl && [
                    {
                      uid: "-1",
                      name: "Durem.pdf",
                      status: "done",
                      url: event?.rulePdfUrl,
                    },
                  ]
                }
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Файл оруулах</Button>
              </Upload>
              <Row
                justify="space-between"
                align="middle"
                style={{ marginTop: 70 }}
              >
                <Col>
                  <Popconfirm
                    title="Устгахдаа итгэлтэй байна уу?"
                    onConfirm={handleDelete}
                    cancelText="Цуцлах"
                    okText="Устгах"
                    okButtonProps={{ danger: true }}
                  >
                    <Button type="primary" danger>
                      Устгах
                    </Button>
                  </Popconfirm>
                </Col>
                <Col>
                  <Button
                    loading={loading}
                    type="primary"
                    disabled={!change}
                    htmlType="submit"
                  >
                    Хадгалах
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </>
      )) || (
        <Row>
          <Spin
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          />
        </Row>
      )}
    </>
  );
};

export default EventDetail;
