import { Modal } from 'antd';
import React from 'react'

let logo = require("../../../../assets/logo.png");
const OrganizerDetailModal = ({ isVisible, onCancel, data }: any) => {
  return (
    <Modal
      title="Зохион байгуулагчийн мэдээлэл"
      key={"organizer_detail"}
      footer={false}
      visible={isVisible}
      onCancel={onCancel}
    >
      <div className="grid grid-cols-2 mb-[2rem]">
        <div className="col-span-2 max-w-[120px] max-h-[150px] overflo-hidden mx-auto mb-[1rem]">
          <img src={logo} alt="Logo" className="mx-auto" />
        </div>
        <p className="font-[700] italic">Зохион байгуулагчийн зураг:</p>
        <img
          src={data?.user.metadata.avatarUrl}
          alt={data?.user.metadata.avatarUrl}
          className="ml-0"
          width={100}
          height={100}
        />
        <p className="font-[700] italic">Овог, нэр:</p>
        <p>
          {data &&
            data?.user.metadata.lastName + " " + data?.user.metadata.firstName}
        </p>

        <p className="font-[700] italic">Регистрийн дугаар:</p>
        <p>{data?.user.metadata.registrationNumber}</p>
        <p className="font-[700] italic">Хүйс:</p>
        <p>
          {data && (data?.user.metadata.gender === 1 ? "Эмэгтэй" : "Эрэгтэй")}
        </p>
        <p className="font-[700] italic">Утасны дугаар:</p>
        <p>{data?.user.metadata.phoneNumber}</p>
        <p className="font-[700] italic">И-мэйл хаяг:</p>
        <p>{data?.user.metadata.email}</p>
      </div>
    </Modal>
  );
};

export default OrganizerDetailModal