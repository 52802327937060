import React from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";

import Marker from "@editorjs/marker";
import List from "@editorjs/list";
import Quote from "@editorjs/quote";
// const Header = require("@editorjs/header");
const Configuration = () => {
  return {
    /**
     * Id of Element that should contain Editor instance
     */
    holder: "editorjs",
    autofocus: true,

    /**
     * Available Tools list.
     * Pass Tool's class or Settings object for each Tool you want to use
     */
    tools: {
      header: Header,
      quote: Quote,
      marker: Marker,
      list: List
    },
    /**
     * Previously saved data that should be rendered
     */
    //  onReady: () => {
    //     console.log('Editor.js is ready to work!')
    //  },
    //  onChange: (api, event) => {

    //     console.log('Now I know that Editor\'s content changed!', event)
    // },
    data: {
      blocks: [],
      version: "2.22.2",
    },
  };
};

export default Configuration;
