import {
  Button,
  Col,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
} from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { apiSport } from "src/api";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import apiPost from "src/api/post";

const NewsList = () => {
  // const [activeRow, setActiveRow] = useState<any>(null);
  const { data, isLoading, refetch } = useQuery(
    "postsList",
    () => {
      return apiPost.getPosts();
    }
  );
  // const handleRow = (e: any) => {
  //   setActiveRow(e);
  // };
  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Гарчиг",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Тайлбар",
      key: "shortDescription",
      render: (row: any) => {
        return (
          <div className="max-h-10 text-ellipsis overflow-hidden	">
            {row.shortDescription}
          </div>
        );
      },
    },
    {
      title: "Огноо",
      key: "updatedAt",
      render: (row: any) => {
        return <>{moment(row.updatedAt).format("L")}</>;
      },
    },
    {
      title: "Үйлдэл",
      key: "actions",
      render: (row: any, __: any, index: number) => {
        return (
          <Space direction="horizontal">
            <Link key={`news-${row["_id"]}`} to={`/news/${row["_id"]}`}>
              <Button
              // onClick={() => {
              //   handleRow(row);
              // }}
              >
                Шинэчлэх
              </Button>
            </Link>
            <Popconfirm
              title="Устгахдаа итгэлтэй байна уу?"
              onConfirm={async () => {
                const result = await apiPost.deletePost(row["_id"]);

                if (result?.statusCode === 0) {
                  notification.success({ message: "Амжилттай устгалаа." });
                  refetch();
                } else {
                  notification.error({ message: result?.error });
                }
              }}
              cancelText="Цуцлах"
              okText="Устгах"
              okButtonProps={{ danger: true }}
            >
              <Button style={{ marginLeft: 30 }} type="primary" danger>
                Устгах
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const [form] = useForm();
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  // const onFinishName = async (values) => {
  //   setLoading(true);
  //   const result = await apiSport.searchSport(values.name);

  //   if (result?.status === "success" && result?.data?.length !== 0) {
  //     setResult(result);
  //     setLoading(false);
  //   } else if (result?.data?.length === 0) {
  //     notification.error({ message: "Спорт олдсонгүй" });
  //     setResult(null);
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      <PageHeader title={"Мэдээ хайх"} />
      <Form
        name="Search with name"
        form={form}
        initialValues={{ remember: true }}
        // onFinish={onFinishName}
        style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto" }}
      >
        <Row style={{ gap: 20 }}>
          <Form.Item name="name" style={{ width: 300 }}>
            <Input placeholder="Нэрээр хайх" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Хайх
            </Button>
          </Form.Item>
        </Row>
      </Form>

      {loading === true ? (
        <Row justify="center" align="middle">
          <Spin />
        </Row>
      ) : (
        result !== null && (
          <>
            <PageHeader title={"Хайлтын илэрц"} />
            <Table rowKey="_id" columns={columns} dataSource={result?.data} />
          </>
        )
      )}

      <Row justify="space-between" align="middle">
        <Col>
          <PageHeader title="Мэдээний жагсаалт" />
        </Col>
        <Col>
          <Button type="primary">
            <Link to="create">Шинээр үүсгэх</Link>
          </Button>
        </Col>
      </Row>

      <Table
        rowKey="_id"
        columns={columns}
        dataSource={data?.data.data}
        loading={isLoading}
        pagination={{
          total: data?.pagination?.total,
        }}
      />
    </>
  );
};

export default NewsList;
