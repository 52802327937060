import axios from "axios";
import { Config } from "../config";

const client = axios.create({
  baseURL: Config.api.baseUrl,
});

client.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");

    if (token && token !== "") {
      config.headers.Authorization = `Bearer ${token}`.replace(/["]+/g, "");
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default client;
