import { useState, useEffect } from "react";
import { Button, Form, Input, Upload, message, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { UploadOutlined } from "@ant-design/icons";
import type { RcFile } from "antd/es/upload/interface";
import EditorJS from "@editorjs/editorjs";
import Configuration from "./configuration";
import { useForm } from "antd/lib/form/Form";
import { apiEvent } from "src/api";
import apiPost from "src/api/post";
import { SteppeHeader } from "src/components";

//  const editor__JS = new EditorJS(Configuration());
const CreatePost = (props) => {
  const [editor, setEditor] = useState<any>("");
  const [editorData, setEditorData] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [file1, setFile1] = useState(false);
  const [form] = useForm();

  useEffect(() => {
    editor === "" && setEditor(() => new EditorJS(Configuration()));
  }, []);

  const createPost = async (values, data) => {
    const tempValues = { ...values, text: data };
    const result = await apiPost.createPost(tempValues);

    if (result?.statusCode === 0) {
      form.resetFields();
      editor.blocks.clear();
      setFile1(null);
      notification.success({ message: "Амжилттай үүсгэлээ." });
    } else {
      notification.error({ message: result?.error });
    }
  };

  const onFinish = async (values) => {
    editor
      .save()
      .then((data) => {
        setEditorData(data);
        createPost(values, data);
      })
      .catch((error) => {
        console.log("Saving failed: ", error);
      });
  };
  const handleImage = async (e: any) => {
    let result: any;
    result = await apiEvent.apiUpload(e.file, "image");
    if (result.success === true) {
      e.file = result.data;

      form.setFieldsValue({
        imageUrl: result.data,
      });
    } else {
      console.error("error: ", result.e);
    }
  };
  const isImage = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Та зөвхөн JPG/PNG форматтай зураг оруулах боломжтой!");
    }
    setFile1(isJpgOrPng);
    return !isJpgOrPng;
  };
  return (
    <>
      <SteppeHeader title="Мэдээ үүсгэх" />
      <Form
        layout={"vertical"}
        form={form}
        name="basic"
        labelCol={{ span: 6 }}
        style={{ paddingLeft: 24 }}
        wrapperCol={{ span: 12 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Гарчиг"
          name="name"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Богино мэдээ"
          name="shortDescription"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <TextArea style={{ height: 200 }} />
        </Form.Item>
        <Form.Item name="imageUrl" hidden={true}>
          {<Input hidden={true} id="imageUrl" />}
        </Form.Item>
        <Form.Item label="Арын зураг">
          <Upload
            maxCount={1}
            listType="picture"
            beforeUpload={isImage}
            onChange={handleImage}
          >
            <Button icon={<UploadOutlined />}>Зураг оруулах</Button>
          </Upload>
        </Form.Item>
        <div>
          <p>Дэлгэрэнгүй мэдээ</p>
          <div
            style={{
              maxWidth: "800px",
              minHeight: "300px",
              // backgroundColor: "#eef5fa",
              //   borderRadius: "50px",
              border: "1px solid rgba(5, 5, 5, 0.06)",
              padding: "30px 10px",
            }}
          >
            <div
              id="editorjs"
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                padding: "50px 30px",
              }}
            />
          </div>
        </div>
        <Form.Item wrapperCol={{ offset: 21, span: 20 }}>
          <Button type="primary" disabled={!file1} htmlType="submit">
            Үүсгэх
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CreatePost;
