import {
  Button,
  Col,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Spin,
  Table,
} from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { apiSport } from "src/api";
import CreateSportModal from "../components/createSportModal";
import { useForm } from "antd/lib/form/Form";

const SportList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data, isLoading, isRefetching, refetch } = useQuery("sports", () => {
    return apiSport.find();
  });

  const onCreate = async (values) => {
    const result = await apiSport.create(values);

    if (result?.statusCode === 0) {
      notification.success({ message: "Амжилттай үүсгэлээ." });
      setIsModalVisible(false);
      refetch();
    } else {
      notification.error({ message: result?.error });
    }
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Нэр",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Холбоо",
      key: "federation",
      dataIndex: ["federation", "name"],
    },
    {
      title: "Ангиллууд",
      key: "ctegories",
      render: (row: any, __: any, index: number) => {
        return (
          <Link
            style={{ marginRight: 20 }}
            key={`categories-${row["_id"]}`}
            to={`/sports/${row["_id"]}`}
          >
            <Button>Ангиллууд</Button>
          </Link>
        );
      },
    },
    {
      title: "Үйлдэл",
      key: "actions",
      render: (row: any, __: any, index: number) => {
        return (
          <Row align="middle">
            {/* <Col className="mr-[1rem]">
              <Button
                onClick={() => {
                  // handleRow(row);
                  // setUpdateModalVisible(true);
                }}
              >
                Засварлах
              </Button>
            </Col> */}

            <Col>
              <Popconfirm
                title="Та тус зохион байгуулагчийг хасах гэж байна?"
                onConfirm={async () => {
                  const result = await apiSport.delete(row["_id"]);

                  // if (result?.statusCode === 0) {
                  //   notification.success({ message: "Амжилттай үүсгэлээ." });
                  // } else {
                  //   notification.error({ message: result?.error });
                  // }
                  refetch();
                }}
                cancelText="Цуцлах"
                okText="Устгах"
                okButtonProps={{ danger: true }}
              >
                <Button danger type="primary">
                  Устгах
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  const [form] = useForm();
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const onFinishName = async (values) => {
    setLoading(true);
    const result = await apiSport.searchSport(values.name);

    if (result?.status === "success" && result?.data?.length !== 0) {
      setResult(result);
      setLoading(false);
    } else if (result?.data?.length === 0) {
      notification.error({ message: "Спорт олдсонгүй" });
      setResult(null);
      setLoading(false);
    }
  };

  return (
    <>
      <PageHeader title={"Спорт хайх"} />
      <Form
        name="Search with name"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinishName}
        style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto" }}
      >
        <Row style={{ gap: 20 }}>
          <Form.Item name="name" style={{ width: 300 }}>
            <Input placeholder="Нэрээр хайх" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Хайх
            </Button>
          </Form.Item>
        </Row>
      </Form>

      {loading === true ? (
        <Row justify="center" align="middle">
          <Spin />
        </Row>
      ) : (
        result !== null && (
          <>
            <PageHeader title={"Хайлтын илэрц"} />
            <Table rowKey="_id" columns={columns} dataSource={result?.data} />
          </>
        )
      )}

      <Row justify="space-between" align="middle">
        <Col>
          <PageHeader title="Спорт жагсаалт" />
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            Шинээр үүсгэх
          </Button>
        </Col>
      </Row>

      <Table
        rowKey="_id"
        columns={columns}
        dataSource={data?.data}
        loading={isLoading || isRefetching}
        pagination={{
          total: data?.pagination?.total,
        }}
      />
      <CreateSportModal
        isVisible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        onSubmit={onCreate}
      />
    </>
  );
};

export default SportList;
