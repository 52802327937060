import api from "./baseApi";

const updateOrganizer = async (organizerId: string, data: any) => {
  const res = api(`/admin/organizers/${organizerId}`).update(data);

  return res;
};

const getOrganizer = (eventId: string, filter: any) => {
  return api(`/admin/organizers/?eventId=${eventId}`).get();
};
const printOrganizer = (data: any) => {
  return api("/admin/organizer/print").create(data);
};
const services = {
  updateOrganizer,
  getOrganizer,
  printOrganizer,
};

const apiPayment = api("/admin/organizers", services);

export default apiPayment;
