import axiosClient from "src/libs/axiosClient";

export type APIType = {
  find: (offset?: number, limit?: number, params?: any) => Promise<any>;
  findOne: (id: string) => Promise<any>;
  update: (object: { id: string; data: any }) => Promise<any>;
  [key: string]: (...params: any) => Promise<any>;
};

const api = (path: string, services = {}): APIType => ({
  get: async () => {
    const res = await axiosClient.get(`${path}`);
    if (res.data?.statusCode !== 0) {
      throw Error(res.data?.message);
    }
    return res.data?.data;
  },

  find: async (offset: number = 0, limit: number = 10, params: any = {}) => {
    const p2 = path + `?limit=${limit}&offset=${offset}`;

    const res = await axiosClient.get(
      p2,
      Object.keys(params).length === 0 ? {} : { params }
    );

    if (res.data?.statusCode !== 0) {
      throw Error(res.data?.message);
    }

    return res.data;
  },
  findOne: async (id: string) => {
    const res = await axiosClient.get(`${path}/${id}`);
    if (res.data?.statusCode !== 0) {
      throw Error(res.data?.message);
    }
    return res.data?.data;
  },
  update: async (data: any) => {
    const { ...tmp } = data;
    // console.log("data:", data);
    const res = await axiosClient.patch(`${path}`, tmp);
    if (res.data?.statusCode !== 0) {
      throw Error(res.data?.message);
    }
    return res.data;
  },
  put: async (id: string, data: any) => {
    const res = await axiosClient.put(`${path}/${id}`, data);
    if (res.data?.statusCode !== 0) {
      throw Error(res.data?.message);
    }
    return res.data?.data;
  },
  create: async (data: any) => {
    const res = await axiosClient.post(`${path}`, data);

    if (res.data?.statusCode !== 0) {
      throw Error(res.data?.message);
    }
    return res.data;
  },
  delete: async (id) => {
    const res = await axiosClient.delete(`${path}/${id}`);
    if (res.data?.statusCode !== 0) {
      throw Error(res.data?.message);
    }
    return res.data?.data;
  },

  ...services,
});

export default api;
