import { Button, Form, Input, message, Modal, Row, Spin, Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { apiEvent } from "src/api";
import type { RcFile } from "antd/es/upload/interface";
import { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";

const SportFederationDetail = (props: any) => {
  const { data, onCancel, onUpdate, isVisible, form } = props;

  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);

  const isImage = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Та зөвхөн JPG/PNG форматтай зураг оруулах боломжтой!");
    }

    return !isJpgOrPng;
  };

  const handleImage = async (e: any) => {
    setLoading(true);
    let result: any;
    result = await apiEvent.apiUpload(e.file, "image");
    if (result.success === true) {
      setLoading(false);
      e.file = result.data;
      form.setFieldsValue({
        logoUrl: result.data,
      });
    } else {
      setLoading(false);
      console.error("error: ", result.e);
    }
  };

  return (
    <>
      <Modal
        title="Дэлгэрэнгүй мэдээлэл"
        key={"create_modal"}
        footer={false}
        visible={isVisible}
        onCancel={onCancel}
      >
        {data !== null ? (
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
              remember: false,
              logoUrl: data?.logoUrl,
              name: data?.name,
              id: data?._id,
            }}
            onChange={() => {
              setChange(true);
            }}
            onFinish={onUpdate}
            style={{ maxWidth: 800 }}
            autoComplete="off"
          >
            <Form.Item
              label="Нэр"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Заавал бөглөнө үү.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="logoUrl" hidden={true}>
              {<Input hidden={false} id="imageUrl" />}
            </Form.Item>

            <Form.Item required={true} label="Арын зураг">
              <Upload
                maxCount={1}
                listType="picture"
                beforeUpload={isImage}
                onChange={handleImage}
                defaultFileList={
                  data?.logoUrl && [
                    {
                      uid: "-1",
                      name: "Background.png",
                      status: "done",
                      url: data?.logoUrl,
                    },
                  ]
                }
              >
                <Button>
                  {loading ? (
                    <LoadingOutlined
                      style={{
                        fontSize: 15,
                      }}
                      spin
                    />
                  ) : (
                    <UploadOutlined />
                  )}
                  Файл оруулах
                </Button>
              </Upload>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                disabled={!change}
                type="primary"
                loading={loading}
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Row justify="center" align="middle">
            <Spin />
          </Row>
        )}
      </Modal>
    </>
  );
};

export default SportFederationDetail;
