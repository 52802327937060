import {
  Button,
  Col,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { apiSport } from "src/api";
import CreateSportCategoryModal from "./createCategoryModal";
import { useForm } from "antd/lib/form/Form";
import UpdateCategoryModal from "./updateCategoryModal";
import { Link } from "react-router-dom";

const SportCategories = (props) => {
  const { _id } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data, isLoading, refetch } = useQuery("sport-categories", () => {
    return apiSport.getCategories(_id);
  });

  const handleDelete = async (categoryId) => {
    const result = await apiSport.deleteCategory(_id, categoryId);

    if (result?.status === 200) {
      notification.success({ message: "Амжилттай устгалаа." });
      setIsCategoryVisible(false);
      refetch();
    } else {
      notification.error({ message: result?.error });
      setIsCategoryVisible(false);
    }

    refetch();
  };

  const [form] = useForm();
  const [activeRow, setActiveRow] = useState<any[]>([]);
  const [isCategoryVisible, setIsCategoryVisible] = useState(false);

  const handleRow = (e: any) => {
    setActiveRow(e);
  };
  useEffect(() => {
    form.resetFields();
  }, [activeRow, form]);

  const onUpdate = async (values) => {
    const { ageMin, ageMax } = values;

    const tmp = { ...values, age: { min:ageMin, max:ageMax } };
    const result = await apiSport.updateCategory(
      _id,
      activeRow?.["_id"],
      tmp
    );

    if (result?.status === 200) {
      notification.success({ message: "Амжилттай үүсгэлээ." });
      setIsCategoryVisible(false);
      refetch();
    } else {
      notification.error({ message: result?.error });
      setIsCategoryVisible(false);
    }
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Код",
      key: "code",
      dataIndex: "code",
    },
    {
      title: "Нэр",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Төрөл",
      key: "type",
      render: (row: any, __: any, index: number) => {
        switch (row["type"]) {
          case 1:
            return "Ганцаарчилсан";
          case 2:
            return `Багийн (${row["participantsCount"]["min"]} - ${row["participantsCount"]["max"]})`;
        }
      },
    },
    {
      title: "Хүйс",
      key: "gender",
      render: (row: any, __: any, index: number) => {
        switch (row["gender"]) {
          case 0:
            return "Зөвхөн эрэгтэй";
          case 1:
            return "Зөвхөн эмэгтэй";
          case 2:
            return "Заавал холимог";
          case 99:
            return "Нээлттэй";
        }
      },
    },

    {
      title: "Насны хязгаар",
      key: "age",
      render: (row: any, __: any, index: number) => {
        return `${row?.age?.min} - ${row?.age?.max}`;
      },
    },
    {
      title: "Үйлдэл",
      key: "actions",
      render: (row: any, __: any, index: number) => {
        return (
          <Row align="middle">
            <Button
              style={{ marginRight: 20 }}
              onClick={() => {
                handleRow(row);
                setIsCategoryVisible(true);
              }}
            >
              Засварлах
            </Button>
            <Popconfirm
              title="Устгахдаа итгэлтэй байна уу?"
              onConfirm={() => {
                handleDelete(row["_id"]);
              }}
              cancelText="Цуцлах"
              okText="Устгах"
              okButtonProps={{ danger: true }}
            >
              <Button type="primary" danger>
                Устгах
              </Button>
            </Popconfirm>
          </Row>
        );
      },
    },
  ];

  const onCategoryCreate = async (values) => {
    const result = await apiSport.createCategory(_id, values);

    if (result?.statusCode === 0) {
      notification.success({ message: "Амжилттай үүсгэлээ." });
      setIsModalVisible(false);
      refetch();
    } else {
      notification.error({ message: result?.error });
    }
  };

  return (
    <>
      <Link to={`/sports`}>
        <Button>Буцах</Button>
      </Link>
      <Row justify="space-between">
        <Col>
          <PageHeader title="Ангилал" />
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            Шинээр үүсгэх
          </Button>
        </Col>
      </Row>
      <Table
        rowKey="_id"
        columns={columns}
        dataSource={data?.data}
        loading={isLoading}
        pagination={{
          total: data?.pagination?.total,
        }}
        scroll={{ x: 1100 }}
      />

      <CreateSportCategoryModal
        isVisible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        onSubmit={onCategoryCreate}
      />
      {isCategoryVisible && (
        <UpdateCategoryModal
          data={activeRow}
          form={form}
          isVisible={isCategoryVisible}
          onCancel={() => {
            setIsCategoryVisible(false);
          }}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

export default SportCategories;
