import { Button, Form, Modal, Select } from "antd";
import { useQuery } from "react-query";
import { apiSport } from "src/api";

const AddCompetitionCategoryModal = (props) => {
  const { sportId, isVisible, onCancel, onSubmit } = props;
  console.log("sportId 3: ", sportId);

  const onFinish = async (values) => {
    onSubmit(values);
  };

  const { data } = useQuery("sport-categories", async () => {
    return apiSport.getCategories(sportId);
  });

  return (
    <Modal
      title="Ангилал сонгох"
      key={"create_modal"}
      footer={false}
      visible={isVisible}
      onCancel={onCancel}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Ангиллууд"
          name="categories"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно үү.",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            // onChange={handleChange}
          >
            {data?.data?.map((el) => (
              <Select.Option key={el._id}>{el.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Нэмэх
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCompetitionCategoryModal;
