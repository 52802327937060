import {
  Button,
  Col,
  Form,
  Input,
  PageHeader,
  Pagination,
  Popconfirm,
  Row,
  Table,
  notification,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import apiPayment from "src/api/apiPayment";

const EventList = () => {
  const [form] = Form.useForm();
  const [formFN] = Form.useForm();

  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);

  const onFinishMail = async (values) => {
    setLoading(true);
    const result = await apiPayment.checkPayment(values);

    if (result?.status === "success") {
      setResult(result);
      setMail(values.email);
      form.resetFields();
      setLoading(false);
    } else if (result?.message === "No invoice found.") {
      notification.error({ message: "Mэйл олдсонгүй" });
    }
  };

  const [friendlyNumber, setFriendlyNumber] = useState(null);
  const [mail, setMail] = useState(null);

  const onFinishFN = async (values) => {
    setLoading(true);
    const result = await apiPayment.checkFriendlyNumber(values);

    if (result?.status === "success") {
      setFriendlyNumber(values.friendlyNumber);
      setResult(result);
      formFN.resetFields();
      setLoading(false);
    } else if (result?.message === "No invoice found.") {
      notification.error({ message: "Гүйлгээ олдсонгүй" });
    }
  };

  const OnCheckPayment = async (values) => {
    // setLoading(true);
    const data = await apiPayment.changePayment(values.friendlyNumber);
    if (data?.status === "success") {
      if (friendlyNumber !== null) {
        onFinishFN({ friendlyNumber: friendlyNumber });
      } else {
        onFinishMail({ email: mail });
      }

      setMail(null);
      setFriendlyNumber(null);

      setLoading(false);
      notification.success({ message: "Амжилттай солигдлоо" });
      // setResult(data);
    } else if (data?.message === "No invoice found.") {
      setLoading(false);
      notification.error({ message: "Mэйл олдсонгүй" });
    }
    refetch();
  };

  const { data, isLoading, refetch } = useQuery(
    ["invoices", offset, limit],
    () => {
      return apiPayment.getPayment(limit, offset);
    },
  );

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${(offset - 1) * limit + index + 1}`;
      },
    },
    {
      title: "И-мэйл хаяг",
      key: "email",
      dataIndex: ["user"],
      // ...getColumnSearchProps("И-мэйл"),
      // onFilter: (value, record) => {
      //   console.log("record: ", record, "value: ", value as number);
      //   return record?.user
      //     ?.toString()
      //     .toLowerCase()
      //     .includes((value as string).toLowerCase());
      // },
      // filteredValue: [searchMail],
      // filterIcon: () => {
      //   return <SearchOutlined />;
      // },
      // onFilter: (value, record) => {
      //   return String(record.user)
      //     .toLowerCase()
      //     .includes(String(value).toLowerCase());
      // },
    },
    {
      title: "Огноо",
      key: "updatedAt",
      dataIndex: ["updatedAt"],
      render: (row: any, __: any, index: number) => {
        return moment(row)?.format("lll");
      },
    },
    {
      title: "Гүйлгээний утга",
      key: "friendlyNumber",
      dataIndex: ["steppePayment", "friendlyNumber"],
      // ...getColumnSearchProps("Гүйлгээний утга"),
      // onFilter: (value, record) => {
      //   return record?.steppePayment?.friendlyNumber
      //     ?.toString()
      //     .toLowerCase()
      //     .includes((value as string).toLowerCase());
      // },
    },
    {
      title: "Төлбөрийн дүн",
      key: "project",
      dataIndex: ["amount"],
      render: (row: any) => {
        return row?.toLocaleString();
      },
    },
    {
      title: "Төлбөрийн статус",
      key: "project",
      render: (row: any, __: any, index: number) => {
        return row?.isPaid ? (
          <>
            {/* <Popconfirm
              title="Та тус төлбөрийн статусыг өөрчлөхдөө итгэлтэй байна уу?"
              onConfirm={() => {
                OnCheckPayment(row?.steppePayment);
              }}
              cancelText="Үгүй"
              okText="Тийм"
              okButtonProps={{ danger: false }}
            > */}
            <Button type="primary">Төлөгдсөн</Button>
            {/* </Popconfirm> */}
          </>
        ) : (
          <>
            <Popconfirm
              title="Та тус төлбөрийн статусыг өөрчлөхдөө итгэлтэй байна уу?"
              onConfirm={() => {
                setLoading(true);
                OnCheckPayment(row?.steppePayment);
                setLoading(false);
              }}
              cancelText="Үгүй"
              okText="Тийм"
              okButtonProps={{ danger: false }}
            >
              <Button type="primary" danger>
                Төлөгдөөгүй
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const searchColumns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Огноо",
      key: "updatedAt",
      dataIndex: ["invoice", "updatedAt"],
      render: (row: any, __: any, index: number) => {
        return moment(row?.updatedAt)?.format("lll");
      },
    },
    {
      title: "Гүйлгээний утга",
      key: "friendlyNumber",
      dataIndex: ["invoice", "steppePayment", "friendlyNumber"],
    },
    {
      title: "Төлбөрийн дүн",
      key: "amount",
      dataIndex: ["invoice", "amount"],
      render: (row: any, __: any, index: number) => {
        return row?.toLocaleString();
      },
    },
    {
      title: "Төлбөрийн статус",
      key: "project",
      dataIndex: ["invoice"],
      render: (row: any, __: any, index: number) => {
        return row?.isPaid ? (
          <>
            <Button type="primary">Төлөгдсөн</Button>
          </>
        ) : (
          <>
            <Popconfirm
              title="Та тус төлбөрийн статусыг өөрчлөхдөө итгэлтэй байна уу?"
              onConfirm={() => {
                setLoading(true);
                OnCheckPayment(row?.steppePayment);
                setLoading(false);
              }}
              cancelText="Үгүй"
              okText="Тийм"
              okButtonProps={{ danger: false }}
            >
              <Button type="primary" danger>
                Төлөгдөөгүй
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader title={"Төлбөр шалгах"} />

      <Form
        name="Search with mail"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinishMail}
        style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto" }}
      >
        <Row style={{ gap: 20 }}>
          <Form.Item name="email" style={{ width: 300 }}>
            <Input placeholder="Төлөгчийн мэйлээр хайх" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Хайх
            </Button>
          </Form.Item>
        </Row>
      </Form>

      <Form
        name="Search with friendlyNumber"
        form={formFN}
        initialValues={{ remember: true }}
        onFinish={onFinishFN}
        style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto" }}
      >
        <Row style={{ gap: 20 }}>
          <Form.Item name="friendlyNumber" style={{ width: 300 }}>
            <Input placeholder="Гүйлгээний утгаар шалгах" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Хайх
            </Button>
          </Form.Item>
        </Row>
      </Form>

      {/* Search result */}
      {!loading && (
        <Table
          rowKey="_id"
          columns={searchColumns}
          scroll={{ x: 900 }}
          loading={loading}
          dataSource={result?.data}
          pagination={false}
        />
      )}

      <Row align="middle">
        <Col>
          <PageHeader title={"Нийт төлбөрийн жагсаалт"} />
        </Col>
        <Col>
          {showTable === true ? (
            <Button
              style={{ marginLeft: 24 }}
              onClick={() => setShowTable(false)}
            >
              Хаах
            </Button>
          ) : (
            <Button
              type="primary"
              style={{ marginLeft: 24 }}
              onClick={() => setShowTable(true)}
            >
              Бүтнээр харах
            </Button>
          )}
        </Col>
      </Row>

      {/* {showTable && ( */}
      <>
        <Table
          rowKey="_id"
          columns={columns}
          scroll={{ x: 900 }}
          loading={isLoading}
          dataSource={data?.data?.data}
          pagination={false}
        />

        {
          <Row justify="center">
            <Pagination
              style={{ marginTop: 50 }}
              current={offset}
              total={Math.ceil(data?.data?.total) || 0}
              showSizeChanger
              showTotal={(total) => (
                <div style={{ alignItems: "middle", display: "flex" }}>
                  {`Нийт ${total} төлбөр`}
                </div>
              )}
              onChange={(page, pageSize) => {
                setLimit(pageSize);
                setOffset(page);
                console.log(page, offset);
              }}
            />
          </Row>
        }
      </>
    </>
  );
};

export default EventList;
