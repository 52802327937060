import axiosClient from "src/libs/axiosClient";
import api from "./baseApi";

const getAthletes = (organizationId: string, filter: any) => {
  return api(`/admin/organizations/${organizationId}/athletes`).find(filter);
};

const getUsers = (organizationId: string, filter: any) => {
  return api(`/admin/organizations/${organizationId}/users`).find(filter);
};

const createUser = (organizationId: string, data: any) => {
  return api(`/admin/organizations/${organizationId}/users`).create(data);
};

const searchOrganization = async (param: string, filter: any) => {
  const p2 = `/admin/organizations?search=${param}`;

  const res = await axiosClient.get(p2);

  if (res.data?.statusCode !== 0) {
    throw Error(res.data?.message);
  }

  return res.data;
};

const updateOrganization = async (values: any, organizationId: string) => {
  const r = await api(`/admin/organizations/${organizationId}`)
    .update(values)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e;
    });

  return r;
};

const deleteOrganization = async (organizationId: string) => {
  const p2 = `/admin/organizations/${organizationId}`;

  const res = await axiosClient.delete(p2);

  if (res.data?.statusCode !== 0) {
    throw Error(res.data?.message);
  }

  return res.data;
};

const getOrganization = async (organizationId: string, filter: any) => {
  return api(`/admin/organizations/${organizationId}`).find(filter);
};

const services = {
  getAthletes,
  getUsers,
  createUser,
  searchOrganization,
  updateOrganization,
  deleteOrganization,
  getOrganization,
};

const apiOrganization = api("/admin/organizations", services);

export default apiOrganization;
