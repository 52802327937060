import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  SelectProps,
  Upload,
  message,
  notification,
} from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import { apiEvent, apiSport } from "src/api";
import apiOrganizer from "src/api/apiOrganizer";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";

const CreateUserModal = (props) => {
  const { isVisible, onCancel, form, refetchList } = props;

  const onFinish = async (values) => {
    setLoading(true);
    if (values.avatarUrl === undefined || values.avatarUrl === "") {
      notification.error({ message: "Tа зургаа заавал оруулах шаардлагатай" });
      return;
    }
    let tmp = letterF + letterS + registration;
    values.registrationNumber = tmp;

    const result = await apiOrganizer.create(values);

    if (result?.statusCode === 0) {
      setLoading(false);
      notification.success({ message: "Амжилттай үүсгэлээ." });
      onCancel(true);
      refetchList();
    } else {
      setLoading(false);
      notification.error({ message: result?.error });
    }
    form.resetFields();
  };

  const [loading, setLoading] = useState(false);

  const [letterF, setLetterF] = useState("");
  const [letterS, setLetterS] = useState("");
  const [registration, setRegistration] = useState();

  const onChange = () => {
    form.setFieldsValue({
      username: form.getFieldValue("email"),
    });
  };

  const onChangeFL = (value) => {
    setLetterF(value);
  };

  const onChangeSL = (value) => {
    setLetterS(value);
  };

  const onChangeNumber = (value) => {
    let tmp = value.target.value.split("").slice(0, 8);
    setRegistration(tmp.join(""));
  };

  const { data, isLoading } = useQuery("sports", () => {
    return apiSport.find();
  });

  const options: SelectProps["options"] = [];

  if (!isLoading) {
    data?.data.map((e) => {
      options.push({ label: e.name, value: e._id });
    });
  }

  const dict = "абвгдеёжзийклмноөпрстуүфхцчшщъыьэюя".toUpperCase().split("");

  const isImage = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    const FILE_SIZE = 2 * 1024 * 1024;
    if (file.size > FILE_SIZE) {
      message.error("Зургийн хэмжээ 2мегабайт-с ихгүй байх шаардлагатай!");
    }

    if (!isJpgOrPng) {
      message.error("Та зөвхөн JPG/PNG форматтай зураг оруулах боломжтой!");
    }
    return !isJpgOrPng;
  };

  const handleImage = async (e: any) => {
    if (e.fileList.length === 0) {
      form.setFieldsValue({
        avatarUrl: undefined,
      });
      setLoading(false);
      // setFile1(false);
    } else {
      setLoading(true);
      let result: any;
      result = await apiEvent.apiUpload(e.file, "image");

      if (result.success === true) {
        e.file = result.data;
        form.setFieldsValue({
          avatarUrl: result.data,
        });
        setLoading(false);
      } else {
        // setLoading(false);
        console.error("error: ", result.e);
      }
    }
  };

  return (
    <Modal
      title="Зохион байгуулагч үүсгэх"
      key={"create_modal"}
      width={700}
      footer={false}
      visible={isVisible}
      onCancel={onCancel}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item label="Цээж зураг">
          <Upload
            listType="picture"
            onChange={handleImage}
            beforeUpload={isImage}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Зураг оруулах</Button>
          </Upload>
        </Form.Item>

        <Form.Item name="avatarUrl" hidden={true}>
          {<Input hidden={false} />}
        </Form.Item>

        <Form.Item label="Нэвтрэх нэр" hidden={true} name="username">
          <Input />
        </Form.Item>

        <Form.Item label="Овог, нэр" style={{ marginBottom: 0 }}>
          <Form.Item
            name="lastName"
            rules={[{ required: false }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
            }}
          >
            <Input placeholder="Овог" />
          </Form.Item>
          <Form.Item
            name="firstName"
            rules={[{ required: false }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input placeholder="Нэр" />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Хүйс"
          name="gender"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно үү.",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Хүйс"
            // onChange={handleChange}
          >
            <Select.Option key="0" value={0}>
              Эрэгтэй
            </Select.Option>
            <Select.Option key="1" value={1}>
              Эмэгтэй
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Төрсөн өдөр"
          name="birthday"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно үү.",
            },
          ]}
        >
          <DatePicker format="YYYY-MM-DD" placeholder="Төрсөн өдөр" />
        </Form.Item>

        <Form.Item
          label="Утасны дугаар"
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: "Заавал оруулна уу",
            },
          ]}
        >
          <Input type="number" placeholder="Утасны дугаар" />
        </Form.Item>

        <Form.Item label="Регистрийн дугаар" className="mb-0">
          <Input.Group compact>
            <Select onChange={onChangeFL} placeholder="A">
              {dict.map((e: any) => {
                return (
                  <Select.Option key={e} value={e}>
                    {e}
                  </Select.Option>
                );
              })}
            </Select>
            <Select onChange={onChangeSL} placeholder="A">
              {dict.map((e: any) => {
                return (
                  <Select.Option key={e} value={e}>
                    {e}
                  </Select.Option>
                );
              })}
            </Select>

            <Form.Item style={{ marginBottom: 0 }}>
              <Input
                onChange={(value) => {
                  onChangeNumber(value);
                }}
                value={registration}
                placeholder="12341234"
                type="number"
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item
          hidden={true}
          style={{ marginBottom: 0 }}
          name="registrationNumber"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="И-мэйл"
          name="email"
          rules={[
            {
              required: true,
              message: "Хүчингүй и-мэйл байна!",
              type: "email",
            },
          ]}
        >
          <Input onChange={onChange} placeholder="И-мэйл хаяг" />
        </Form.Item>

        <Form.Item
          label="Хариуцаж буй спорт"
          name="sports"
          rules={[
            {
              required: false,
              message: "Заавал сонгоно үү.",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Хариуцаж буй спорт"
            options={options}
          />
        </Form.Item>

        <Form.Item
          label="Харъяа байгууллага"
          name="organization"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно үү.",
            },
          ]}
        >
          <Input placeholder="Харъяа байгууллага" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            disabled={!(letterF && letterS)}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Үүсгэх
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUserModal;
