import axiosClient from "src/libs/axiosClient";
import api from "./baseApi";

const getCategories = (sportId: string, filter: any) => {
  return api(`/admin/sports/${sportId}/categories`).find(filter);
};

const createCategory = (sportId: string, data: any) => {
  return api(`/admin/sports/${sportId}/categories`).create(data);
};

const updateCategory = async (
  sportId: string,
  categoryId: string,
  data: any
) => {
  // return api(`/admin/sports/${sportId}/categories/${categoryId}`).update(data);

  const res = axiosClient.patch(
    `/admin/sports/${sportId}/categories/${categoryId}`,
    data
  );

  return res;
};

const searchSport = async (param: string, filter: any) => {
  const p2 = `/admin/sports?search=${param}`;

  const res = await axiosClient.get(p2);

  if (res.data?.statusCode !== 0) {
    throw Error(res.data?.message);
  }

  return res.data;
};

const deleteCategory = async (sportId: string, categoryId: string) => {
  // return api(`/admin/sports/${sportId}/categories`).delete(categoryId);

  // const res = axiosClient.patch(
  //   `/admin/sports/${sportId}/categories/${categoryId}`,
  //   data
  // );

  const res = await axiosClient.delete(
    `/admin/sports/${sportId}/categories/${categoryId}`
  );

  return res;
};

const services = {
  getCategories,
  createCategory,
  deleteCategory,
  searchSport,
  updateCategory,
};

const apiSport = api("/admin/sports", services);

export default apiSport;
