import { Button, Col, notification, Row } from "antd";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { apiEvent } from "src/api";
import CompetitionCategories from "./components/CompetitionCategories";
import AddCompetitionCategoryModal from "./components/AddCompetitionCategoryModal";
import { useState } from "react";

const CompetitionDetail = () => {
  const { eventId, competitionId } = useParams();

  const { data, refetch } = useQuery(
    ["competition-detail", competitionId],
    async () => {
      return (await apiEvent.competitions(eventId)).findOne(competitionId);
    }
  );

  const onCategoryAdd = async (values) => {
    const result = await apiEvent.addCategories(eventId, competitionId, values);
    if (result?.statusCode === 0) {
      notification.success({ message: "Амжилттай нэмэгдлээ." });
      setIsModalVisible(false);
      refetch();
    } else {
      notification.error({ message: result?.error });
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Row align="middle" justify="space-between">
        <Col>
          <Link to={`/regsys/events/${eventId}/competitions`}>
            <Button
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              Буцах
            </Button>
          </Link>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setIsModalVisible(true);
            }}
            className="mr-[1rem]"
          >
            Ангилал нэмэх
          </Button>
        </Col>
      </Row>

      <CompetitionCategories
        eventId={eventId}
        competitionId={competitionId}
        sportId={data?.sport}
        categories={data?.categories}
        refetch={refetch}
      />
      <Row
        justify="space-between"
        align="middle"
        // style={{ textAlign: "right" }}
      >
        {/* <Popconfirm
          title="Устгахдаа итгэлтэй байна уу?"
          onConfirm={handleDelete}
          cancelText="Цуцлах"
          okText="Устгах"
          okButtonProps={{ danger: true }}
        >
          <Button type="primary" danger>
            Устгах
          </Button>
        </Popconfirm> */}

        <Col style={{ marginLeft: "90%" }}></Col>
      </Row>

      {data?.sport && (
        <AddCompetitionCategoryModal
          sportId={data?.sport}
          isVisible={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          onSubmit={onCategoryAdd}
        />
      )}
    </>
  );
};

export default CompetitionDetail;
