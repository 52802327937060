/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { Layout, Menu } from "antd";
import { useState } from "react";
import useAuth from "../hooks/useAuth";
import {
  menuItemsAdmin,
  menuItemsJudge,
  menuItemsManager,
} from "src/config/menuItems";
import { Link, useLocation } from "react-router-dom";

const { Content, Footer, Sider } = Layout;

let logo = require("../assets/logo.png");

const DefaultLayout = (props: any) => {
  const { user, role } = useAuth();
  const location = useLocation();
  const { pathname } = location;
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        style={{ height: "100vh", position: "fixed" }}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Link to={`/`}>
          <div
            className="logo"
            style={{ marginBottom: 20, padding: 20, backgroundColor: "#fff" }}
          >
            <img src={logo} alt="Logo" />
          </div>
        </Link>

        {user?.claims?.metadata?.role === "admin" ? (
          <Menu
            theme="dark"
            defaultSelectedKeys={["1"]}
            selectedKeys={[pathname]}
            mode="inline"
            items={menuItemsAdmin}
          />
        ) : user?.claims?.metadata?.role === "judge" ? (
          <Menu
            theme="dark"
            defaultSelectedKeys={["1"]}
            selectedKeys={[pathname]}
            mode="inline"
            items={menuItemsJudge}
          />
        ) : user?.claims?.metadata?.role === "manager" ? (
          <Menu
            theme="dark"
            defaultSelectedKeys={["1"]}
            selectedKeys={[pathname]}
            mode="inline"
            items={menuItemsManager}
          />
        ) : (
          <></>
        )}
      </Sider>
      <Layout className="site-layout overflow-hidden maw-w-[1900px] mx-auto">
        <Content style={{ margin: "0 16px" }}>
          <div
            className="site-layout-background max-w-[1800px]"
            style={{
              marginTop: "16px",
              padding: 24,
              minHeight: 360,
              marginLeft: 200,
            }}
          >
            {props.children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          SteppeLink ©{new Date().getFullYear()}.
        </Footer>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
