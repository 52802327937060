import { Button, Popconfirm } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";

export const menuItemsJudge = [
  {
    key: "/regsys/judges",
    label: <Link to="/regsys/judges">Шүүгчид</Link>,
  },
  {
    key: "/logout",
    label: (
      <Popconfirm
        title="Та системээс гарах гэж байна."
        onConfirm={() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          // Navigate("/regsys/events");
          window.location.reload();
        }}
        cancelText="Үгүй"
        okText="Тийм"
        okButtonProps={{ danger: true }}
      >
        <Button
          type="primary"
          danger
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Гарах
          <LogoutOutlined
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </Button>
      </Popconfirm>
    ),
  },
];

export const menuItemsManager = [
  // {
  //   key: "/regsys/judges",
  //   label: <Link to="/regsys/judges">Шүүгчид</Link>,
  // },
  {
    key: "/logout",
    label: (
      <Popconfirm
        title="Та системээс гарах гэж байна."
        onConfirm={() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          // Navigate("/regsys/events");
          window.location.reload();
        }}
        cancelText="Үгүй"
        okText="Тийм"
        okButtonProps={{ danger: true }}
      >
        <Button
          type="primary"
          danger
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Гарах
          <LogoutOutlined
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </Button>
      </Popconfirm>
    ),
  },
];

export const menuItemsAdmin = [
  {
    key: "/sports/federations",
    label: <Link to="/sports/federations">Спорт холбоод</Link>,
  },
  {
    key: "/sports",
    label: <Link to="/sports">Спорт</Link>,
  },
  {
    label: <Link to="/organizations">Байгууллага</Link>,
    key: "/organizations",
  },
  {
    key: "/news",
    label: <Link to="/news">Мэдээ мэдээлэл</Link>,
  },
  {
    key: "/regsys/events",
    label: <Link to="/regsys/events">Эвент</Link>,
  },
  {
    key: "/regsys/payment",
    label: <Link to="/regsys/payment">Төлбөр</Link>,
  },
  {
    key: "/regsys/organizers",
    label: <Link to="/regsys/organizers">Зохион байгуулагчид</Link>,
  },
  {
    key: "/regsys/judges",
    label: <Link to="/regsys/judges">Шүүгчид</Link>,
  },
  {
    key: "/logout",
    label: (
      <Popconfirm
        title="Та системээс гарах гэж байна."
        onConfirm={() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          // Navigate("/regsys/events");
          window.location.reload();
        }}
        cancelText="Үгүй"
        okText="Тийм"
        okButtonProps={{ danger: true }}
      >
        <Button
          type="primary"
          danger
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Гарах
          <LogoutOutlined
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </Button>
      </Popconfirm>
    ),
  },
  // {
  //   key: "/logout",
  //   placement: "bottom",
  //   label: (
  //     <Row justify="center" align="middle" style={{ marginTop: "auto" }}>
  //       <Button
  //         type="primary"
  //         style={{ paddingLeft: 20, paddingRight: 20, marginTop: "auto" }}
  //       >
  //         Гарах
  //       </Button>
  //     </Row>
  //   ),
  // },
];

// export default menuItemsAdmin;
