import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import { apiEvent, apiSport } from "src/api";
import type { RcFile } from "antd/es/upload/interface";
import { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { useQuery } from "react-query";

const SportFederationDetail = (props: any) => {
  const { data, onCancel, onUpdate, isVisible, form, loading } = props;

  const sports = useQuery("sports", () => {
    return apiSport.find();
  });

  const [file1, setFile1] = useState(true);

  const [change, setChange] = useState(false);

  const handlePdf = async (e: any) => {
    let result: any;
    result = await apiEvent.apiUpload(e.file, "pdf");

    if (result.success === true) {
      e.file = result.data;
      form.setFieldsValue({
        pdfUrl: result.data,
      });
    } else {
      console.error("error: ", result.e);
    }
  };

  const isPDF = (file: RcFile) => {
    const isJpgOrPng = file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("Та зөвхөн PDF файл оруулах боломжтой!");
    }
    setFile1(isJpgOrPng);
    return !isJpgOrPng;
  };

  return (
    <>
      <Modal
        title="Дэлгэрэнгүй мэдээлэл"
        key={"create_modal"}
        footer={false}
        width={800}
        visible={isVisible}
        onCancel={onCancel}
      >
        {data !== null ? (
          <Form
            name="basic"
            form={form}
            onChange={() => {
              setChange(true);
            }}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
              remember: false,
              sportId: data?.sport?._id,
              name: data?.name,
              pdfUrl: data?.pdfUrl,
              startAt: moment(data?.startAt),
              endAt: moment(data?.endAt),
              isRegisterOpen: data?.isRegisterOpen,
              basePrice: data?.basePrice,
              priceMultiplier: data?.priceMultiplier,
              priceMaxCount: data?.priceMaxCount,
            }}
            onFinish={onUpdate}
            style={{ maxWidth: 800 }}
            autoComplete="off"
          >
            <Form.Item
              label="Спорт"
              name="sportId"
              rules={[
                {
                  required: true,
                  message: "Заавал бөглөнө үү.",
                },
              ]}
            >
              <Select
                onChange={() => {
                  setChange(true);
                }}
              >
                {sports?.data?.data?.map((el) => (
                  <Select.Option key={el._id} value={el._id}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Нэр"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Заавал бөглөнө үү.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="pdfUrl" hidden={true}>
              {<Input hidden={false} />}
            </Form.Item>

            <Form.Item
              label="Удирдамж"
              rules={[
                {
                  required: true,
                  message: "Файл оруулна уу.",
                },
              ]}
            >
              <Upload
                onChange={handlePdf}
                beforeUpload={isPDF}
                defaultFileList={
                  data?.pdfUrl && [
                    {
                      uid: "-1",
                      name: data?.pdfUrl,
                      status: "done",
                      url: data?.pdfUrl,
                    },
                  ]
                }
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Файл оруулах</Button>
              </Upload>
            </Form.Item>

            <Form.Item
              label="Бүртгэлийн статус"
              name="isRegisterOpen"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно уу.",
                },
              ]}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: "Нээлттэй",
                  },
                  {
                    value: false,
                    label: "Хаалттай",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              label="Хураамж"
              name="basePrice"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно уу.",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>

            <Form.Item
              label="priceMultiplier"
              name="priceMultiplier"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно уу.",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>

            <Form.Item
              label="priceMaxCount"
              name="priceMaxCount"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно уу.",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>

            <Form.Item
              label="Эхлэх огноо"
              name="startAt"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно уу.",
                },
              ]}
            >
              <DatePicker
                onChange={() => {
                  setChange(true);
                }}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{
                  defaultValue: moment("00:00:00", "HH:mm:ss"),
                }}
              />
            </Form.Item>
            <Form.Item
              label="Дуусах огноо"
              name="endAt"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно уу.",
                },
              ]}
            >
              <DatePicker
                onChange={() => {
                  setChange(true);
                }}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{
                  defaultValue: moment("00:00:00", "HH:mm:ss"),
                }}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                disabled={!change}
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Row justify="center" align="middle">
            <Spin />
          </Row>
        )}
      </Modal>
    </>
  );
};

export default SportFederationDetail;
