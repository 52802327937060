import { Button, Form, Input, InputNumber, Modal, Select } from "antd";
import { useState } from "react";

const CreateSportCategoryModal = (props) => {
  const { isVisible, onCancel, onSubmit } = props;

  const [selectedType, setSelectedType] = useState(null);

  const onFinish = async (values) => {
    onSubmit(values);
  };

  return (
    <Modal
      title="Ангилал үүсгэх"
      key={"create_modal"}
      footer={false}
      visible={isVisible}
      onCancel={onCancel}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Код"
          name="code"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Нэр"
          name="name"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Төрөл"
          name="type"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно үү.",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            onChange={(value) => {
              setSelectedType(value);
            }}
          >
            <Select.Option key="1" value="1">
              Ганцаарчилсан
            </Select.Option>
            <Select.Option key="2" value="2">
              Багийн
            </Select.Option>
          </Select>
        </Form.Item>

        {selectedType === "2" && (
          <>
            <Form.Item
              label="Гишүүд : Доод"
              name="participantsCountMin"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно үү.",
                },
              ]}
              initialValue={1}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={1}
                max={100}
                defaultValue={1}
              />
            </Form.Item>
            <Form.Item
              label="Гишүүд : Дээд"
              name="participantsCountMax"
              rules={[
                {
                  required: true,
                  message: "Заавал сонгоно үү.",
                },
              ]}
              initialValue={5}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={1}
                max={100}
                defaultValue={5}
              />
            </Form.Item>
          </>
        )}

        <Form.Item
          label="Хүйс"
          name="gender"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно үү.",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            // onChange={handleChange}
          >
            <Select.Option key="0" value={0}>
              Зөвхөн эрэгтэй
            </Select.Option>
            <Select.Option key="1" value={1}>
              Зөвхөн эмэгтэй
            </Select.Option>
            <Select.Option key="2" value={2}>
              Заавал холимог
            </Select.Option>
            <Select.Option key="99" value={99}>
              Нээлттэй
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Нас : Доод"
          name="ageMin"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно үү.",
            },
          ]}
          initialValue={1}
        >
          <InputNumber
            style={{ width: "100%" }}
            min={1}
            max={100}
            defaultValue={1}
          />
        </Form.Item>

        <Form.Item
          label="Нас : Дээд"
          name="ageMax"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно үү.",
            },
          ]}
          initialValue={1}
        >
          <InputNumber
            style={{ width: "100%" }}
            min={1}
            max={100}
            defaultValue={1}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Үүсгэх
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateSportCategoryModal;
