import { notification, Table } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import apiOrganization from "src/api/apiOrganization";
import { SteppeHeader } from "src/components";
import CreateUserModal from "./CreateUserModal";

interface IPros {
  organizationId: string;
}
const UserList = (props: IPros) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { organizationId } = props;
  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Нэвтрэх нэр",
      key: "username",
      dataIndex: "username",
    },
    {
      title: "Үүрэг",
      key: "project",
      dataIndex: ["metadata", "role"],
    },
  ];

  const onCreate = async (values) => {
    setLoading(true);
    const result = await apiOrganization.createUser(organizationId, values);

    if (result?.statusCode === 0) {
      notification.success({ message: "Амжилттай үүсгэлээ." });
      setLoading(false);
      setIsModalVisible(false);
      refetch();
    } else {
      setLoading(false);
      notification.error({ message: result?.error });
    }
  };

  const { data, isLoading, isRefetching, refetch } = useQuery("users", () => {
    return apiOrganization.getUsers(organizationId, {});
  });

  return (
    <div style={{ marginTop: 80 }}>
      <SteppeHeader
        title="Mенежерийн жагсаалт"
        onCreateClick={() => {
          setIsModalVisible(true);
        }}
        // disabled={data?.data?.length > 0 ? true : false}
      />
      <Table
        rowKey="_id"
        columns={columns}
        dataSource={data?.data}
        loading={isLoading}
        pagination={{
          total: data?.pagination?.total,
        }}
      />

      <CreateUserModal
        isVisible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        onSubmit={onCreate}
        loading={loading}
      />
    </div>
  );
};

export default UserList;
