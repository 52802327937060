import axiosClient from "src/libs/axiosClient";
import api from "./baseApi";

const updateEvent = async (values: any, eventId: string) => {
  const r = await api(`/admin/events/${eventId}`)
    .update(values)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return e;
    });

  return r;
};

const deleteEvent = async (eventId: string) => {
  const p2 = `/admin/events/${eventId}`;

  const res = await axiosClient.delete(p2);

  if (res.data?.statusCode !== 0) {
    throw Error(res.data?.message);
  }

  return res.data;
};

const createUser = (data: any, userId: string, organizationId: string) => {
  const api2 = api(`/admin/organizations/${organizationId}/users`);

  return api2.create(data);
};

const getUsers = (eventId: string, competitionId: string, filter: any) => {
  return api(
    `/admin/events/${eventId}/competitions/${competitionId}/entries`
  ).find(filter);
};

const checkUsers = async (organizationId: string, data: any) => {
  const res = await axiosClient.post(
    `/admin/organizations/${organizationId}/users`,
    data
  );

  return res.data;
};

const deleteUser = (data: any, userId) => {};

const createCompetition = (eventId: string, data: any) => {
  return api(`/admin/events/${eventId}/competitions`).create(data);
};

const updateCompetition = async (
  eventId: string,
  competitionId: string,
  data: any
) => {
  const res = await axiosClient.patch(
    `/admin/events/${eventId}/competitions/${competitionId}`,
    data
  );
  // const r = await api(`/admin/events/${eventId}/competitions/${competitionId}`)
  //   .update(data)
  //   .then((response) => {
  //     console.log(response);
  //     return response;
  //   });
  // .catch((e) => {
  //   return e;
  // });
  return res;
};

const getCompetitionCategories = (
  eventId: string,
  competitionId: string,
  filter: any
) => {
  return api(
    `/admin/events/${eventId}/competitions/${competitionId}/categories`
  ).find(filter);
};

const apiUpload = async (file, type) => {
  try {
    const formData = new FormData();
    if (type === "image") {
      formData.append("image", file);

      const res = await api(`/upload/image`).create(formData);
      return { success: true, data: res?.data?.path };
    } else if (type === "pdf") {
      formData.append("image", file);

      const res = await api(`/upload/pdf/admin`).create(formData);
      // console.log("result: ", res);
      return { success: true, data: res?.data?.path };
    }
    // else if (type === "rulePdf") {
    //   formData.append("pdf", file);
    //   const res = await api(`/upload/pdf`).create(formData);
    //   return { success: true, data: res?.data?.path };
    // }
  } catch (e) {
    return { success: false, e };
  }
};

const apiPrint = (eventId, competitionId, filter) => {
  return api(
    `/admin/events/${eventId}/competitions/${competitionId}/singles`
  ).find(filter);
};

const competitions = (eventId: string) => {
  return api(`/admin/events/${eventId}/competitions`);
};

const deleteCompetition = async (eventId: string, competitionId: string) => {
  const p2 = `/admin/events/${eventId}/competitions/${competitionId}`;

  const res = await axiosClient.delete(p2);

  if (res.data?.statusCode !== 0) {
    throw Error(res.data?.message);
  }

  return res.data;
};

const addCategories = (eventId, competitionId, data) => {
  const api2 = api(
    `/admin/events/${eventId}/competitions/${competitionId}/categories`
  );

  return api2.create(data);
};

const removeCategory = async (
  eventId: string,
  competitionId: string,
  categoryId: string
) => {
  const p2 = `/admin/competitions/${competitionId}/categories/${categoryId}`;

  const res = await axiosClient.delete(p2);

  if (res.data?.statusCode !== 0) {
    throw Error(res.data?.message);
  }

  return res.data;
};

const printUser = (competitionId: string, data: any) => {
  const api2 = api(`/admin/competitions/${competitionId}/athlete/print`);

  return api2.create(data);
};

const printCoach = (competitionId: string, data: any) => {
  const api2 = api(`/admin/competitions/${competitionId}/coach/print`);

  return api2.create(data);
};

const getRegistrations = (
  eventId: string,
  competitionId: string,
  filter: any
) => {
  return api(
    `/admin/events/${eventId}/competitions/${competitionId}/registrations`
  ).find(filter);
};
const services = {
  createCompetition: createCompetition,
  getCompetitionCategories,
  competitions,
  deleteCompetition,
  addCategories,
  removeCategory,
  apiUpload,
  updateEvent,
  deleteEvent,
  createUser,
  getUsers,
  deleteUser,
  updateCompetition,
  checkUsers,
  apiPrint,
  printUser,
  printCoach,
};

const apiEvent = api("/admin/events", services);

export default apiEvent;
