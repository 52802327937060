import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Layout,
  notification,
  PageHeader,
  Row,
  Select,
  SelectProps,
  Table,
} from "antd";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import apiOrganizer from "src/api/apiOrganizer";
import OrganizerDetailModal from "./OrganizerDetailModal";
import { useForm } from "antd/es/form/Form";
import Popconfirm from "antd/es/popconfirm";

const Orginazer = () => {
  const [form] = useForm();
  const [person, setPerson] = React.useState(null);
  const [role, setRole] = React.useState(null);
  const [isVisible, setIsVisible] = React.useState(false);
  const [selectedAthletes, setSelectedAthletes] = React.useState(null);
  const [pdfUrl, setPdfUrl] = React.useState("");
  const [isDisabled, setIsdisabled] = React.useState<boolean>(true);

  const { data, isLoading, refetch } = useQuery("assignedOrganizer", () => {
    return apiOrganizer.getOrganizer(eventId);
  });
  const { data: organizers, status } = useQuery("organizer", () => {
    return apiOrganizer.get();
  });
  const { eventId } = useParams();

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      let tmpAthlete = selectedRows.map((e) => {
        return e.user._id;
      });
      setSelectedAthletes(tmpAthlete);
      setIsdisabled(selectedRowKeys.length !== 0 ? false : true);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };
  const onFinish = async () => {
    let id = person.user._id;

    const keys = Object.keys(person.user.metadata);
    let tempV = {};
    for (let i = 0; i < keys.length; i++) {
      tempV[keys[i]] = person.user.metadata[keys[i]];
    }

    const result = await apiOrganizer.updateOrganizer(id, {
      ...tempV,
      username: person.username,
      password: person.password,
      events: [eventId],
      mainRole: role,
    });

    if (result?.status === "success") {
      refetch();
      form.resetFields();
      notification.success({ message: "Амжилттай." });
    } else {
      notification.error({ message: result?.error });
    }
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Овог, Нэр",
      key: "index",
      dataIndex: ["user", "metadata"],
      render: (row: any) => {
        return <div>{row.lastName + " " + row.firstName}</div>;
      },
    },
    {
      title: "И-мэйл",
      key: "email",
      dataIndex: ["user", "metadata", "email"],
    },
    {
      title: "Үүрэг",
      key: "index",
      dataIndex: ["user", "metadata"],
      render: (row: any) => {
        return (
          <div className="flex gap-1 max-w-[300px] flex-wrap">
            {row.mainRole}
          </div>
        );
      },
    },
    {
      title: "Үйлдэл",
      key: "index",
      render: (row: any, __: any, index: number) => {
        return (
          <>
            <Row align="middle" className="min-w-max">
              <Col className="mr-[1rem]">
                <Button
                  onClick={() => {
                    setPerson(row);
                    setIsVisible(true);
                  }}
                >
                  Дэлгэрэнгүй
                </Button>
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  const options: SelectProps["options"] = [];
  if (status === "success" && organizers) {
    organizers?.map((e) => {
      options.push({
        label:
          e?.user?.metadata?.lastName[0] + ". " + e?.user?.metadata?.firstName,
        value: e?.user?._id,
      });

      return null; // TODO wtf
    });
  }
  // let logo = require("../../../../assets/logo.png");

  return (
    <Layout className="layout">
      <Row align="middle" style={{ maxWidth: 1000 }} justify="space-between">
        <Col>
          <Row align="middle">
            <PageHeader
              title={"Эвентийг зохион байгуулагчид"}
              style={{ paddingLeft: 0 }}
            />
          </Row>
        </Col>
        <Col>
          <Popconfirm
            title="Та сонгосон шүүгчдийн мандатын хэвлэх гэж байна?"
            onConfirm={async () => {
              // Print athlete
              const tmp = { userIds: selectedAthletes };
              await apiOrganizer.printOrganizer(tmp).then((res) => {
                setPdfUrl(res.data);
                window.open(pdfUrl, "_blank");
              });
            }}
            cancelText="Болих"
            okText="Хэвлэх"
            okButtonProps={{ danger: false }}
          >
            <Button type="primary" disabled={isDisabled}>
              Mандат хэвлэх
            </Button>
          </Popconfirm>
        </Col>
        {pdfUrl !== "" && (
          <Col>
            <a target="_blank" href={pdfUrl} rel="noreferrer">
              <Button
                onClick={() => {
                  setPdfUrl("");
                }}
              >
                Mандатын PDF татах линк
              </Button>
            </a>
          </Col>
        )}
      </Row>
      <Row justify="space-between">
        <Col>
          <Table
            className="mb-[1rem]"
            rowKey={(record) => record.user._id}
            rowSelection={rowSelection}
            columns={columns}
            loading={isLoading}
            scroll={{ x: 1000 }}
            dataSource={data}
            pagination={{
              total: data?.pagination?.total,
            }}
          />
        </Col>
        <Col
          style={{
            marginRight: "auto",
            marginLeft: "auto",
            marginBottom: 20,
          }}
        >
          <Card
            title="Зохион байгуулагч нэмэх"
            style={{
              width: 400,
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Form
              form={form}
              initialValues={[
                { required: true, organizers: "63ba7fce99e8abde30b0c0fb" },
              ]}
              onFinish={onFinish}
            >
              <Form.Item
                name="organizers"
                rules={[
                  {
                    required: true,
                    message: "Заавал сонгоно үү.",
                  },
                ]}
              >
                <Select
                  allowClear
                  onChange={(value) => {
                    const tmp = organizers?.filter((e) => e.user._id === value);
                    setPerson(tmp[0]);
                  }}
                  style={{ width: "100%" }}
                  placeholder="Зохион байгуулагчид"
                  options={options}
                />
              </Form.Item>

              <Form.Item
                name="role"
                rules={[
                  {
                    required: true,
                    message: "Заавал сонгоно үү.",
                  },
                ]}
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  onChange={(value) => setRole(value)}
                  placeholder="Үүрэг"
                  options={[
                    {
                      label: "Сайн дурын ажилтан",
                      value: "Сайн дурын ажилтан",
                    },
                    {
                      label: "Шууд дамжуулалт",
                      value: "Шууд дамжуулалт",
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item>
                <Row>
                  <Col style={{ textAlign: "center" }}>
                    <Button
                      // loading={loading}
                      htmlType="submit"
                    >
                      Нэмэх
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <OrganizerDetailModal
        isVisible={isVisible}
        data={person}
        onCancel={() => {
          setIsVisible(false);
        }}
      />
    </Layout>
  );
};

export default Orginazer;
