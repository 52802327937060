import * as React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Layout,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  SelectProps,
  Table,
} from "antd";
import { useQuery } from "react-query";
import { useForm } from "antd/lib/form/Form";
import { useParams } from "react-router-dom";
import apiJudge from "src/api/apiJudge";
import RefereeModal from "./RefereeModal";

// let logo = require("../../../../assets/logo.png");

const Referee = () => {
  const [person, setPerson] = React.useState(null);
  // const [loading, setLoading] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const { eventId } = useParams();
  const [selectedAthletes, setSelectedAthletes] = React.useState(null);
  const [pdfUrl, setPdfUrl] = React.useState("");
  const [isDisabled, setIsdisabled] = React.useState<boolean>(true);
  const { data } = useQuery("referee", () => {
    return apiJudge.get();
  });
  const [form] = useForm();
  const {
    // status,
    data: datas,
    refetch: submit,
    // isLoading: isLoadingSub,
  } = useQuery(
    "updateRef",
    () => {
      let id = person.user._id;
      const keys = Object.keys(person.user.metadata);
      let tempV = {};
      for (let i = 0; i < keys.length; i++) {
        tempV[keys[i]] = person.user.metadata[keys[i]];
      }
      return apiJudge.updateJudge(
        {
          ...tempV,
          username: person.username,
          password: person.password,
          events: [eventId],
        },
        id
      );
    },
    { enabled: false }
  );
  const {
    data: registredReferee,
    // status: statusRef,
    // refetch: refetchRef,
    // isRefetching,
    isLoading: forRealLoading,
  } = useQuery(["registredReferee", datas], () => {
    return apiJudge.getRegisteredReferee(eventId);
  });

  const onFinish = async () => {
    submit();
    form.resetFields();
    // if (status === "success") {
    //   setLoading(false);
    //   notification.success({ message: "Амжилттай үүсгэлээ." });
    // } else {
    //   setLoading(false);
    //   notification.error({ message: status });
    // }
    // form.resetFields();
  };
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      let tmpAthlete = selectedRows.map((e) => {
        return e.user._id;
      });

      setSelectedAthletes(tmpAthlete);
      setIsdisabled(selectedRowKeys.length !== 0 ? false : true);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };
  const options: SelectProps["options"] = [];
  if (data) {
    data?.map((e) => {
      options.push({
        label:
          e?.user?.metadata?.lastName[0] + "." + e?.user?.metadata?.firstName,
        value: e?.user?._id,
      });

      return null; // TODO ?
    });
  }
  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Овог Нэр",
      key: "index",
      render: (row: any) => {
        return (
          <div>
            {row?.user?.metadata?.lastName[0] +
              ". " +
              row?.user?.metadata?.firstName}
          </div>
        );
      },
    },
    {
      title: "Хариуцаж буй спорт",
      key: "index",
      dataIndex: ["sportsObj"],
      render: (row: any) => {
        return (
          <div className="flex gap-1 max-w-[300px] flex-wrap">
            {row?.map((e: any) => {
              return <Button key={e._id}>{e.name}</Button>;
            })}
          </div>
        );
        // return <p>{row.events}</p>;
      },
    },
    {
      title: "Үйлдэл",
      key: "index",
      render: (row: any, __: any, index: number) => {
        return (
          <>
            <Row align="middle" className="min-w-max">
              <Col className="mr-[1rem]">
                <Button
                  onClick={() => {
                    setPerson(row);
                    setIsVisible(true);
                  }}
                >
                  Дэлгэрэнгүй
                </Button>
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  return (
    <Layout className="layout">
      <Row align="middle" style={{ maxWidth: 1000 }} justify="space-between">
        <Col>
          <Row align="middle">
            <PageHeader
              title={"Шүүгчдийн жагсаалт"}
              style={{ paddingLeft: 0 }}
            />
          </Row>
        </Col>
        <Col>
          <Popconfirm
            title="Та сонгосон шүүгчдийн мандатын хэвлэх гэж байна?"
            onConfirm={async () => {
              // Print athlete
              const tmp = { userIds: selectedAthletes };
              await apiJudge.printJudge(tmp).then((res) => {
                setPdfUrl(res.data);
                window.open(pdfUrl, "_blank");
              });
            }}
            cancelText="Болих"
            okText="Хэвлэх"
            okButtonProps={{ danger: false }}
          >
            <Button type="primary" disabled={isDisabled}>
              Mандат хэвлэх
            </Button>
          </Popconfirm>
        </Col>
        {pdfUrl !== "" && (
          <Col>
            <a target="_blank" href={pdfUrl} rel="noreferrer">
              <Button
                onClick={() => {
                  setPdfUrl("");
                }}
              >
                Mандатын PDF татах линк
              </Button>
            </a>
          </Col>
        )}
      </Row>

      <Row justify="space-between">
        <Col>
          <Table
            rowKey={(record) => record.user._id}
            rowSelection={rowSelection}
            columns={columns}
            loading={forRealLoading}
            scroll={{ x: 1000 }}
            dataSource={registredReferee}
          />
        </Col>
        <Col
          style={{
            marginRight: "auto",
            marginLeft: "auto",
            marginBottom: 20,
          }}
        >
          <Card
            title="Шүүгч нэмэх"
            style={{
              width: 400,
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Form onFinish={onFinish} form={form}>
              <Form.Item
                name="referee"
                rules={[
                  {
                    required: true,
                    message: "Заавал сонгоно үү.",
                  },
                ]}
              >
                <Select
                  allowClear
                  onChange={(value) => {
                    const tmp = data.filter((e) => e.user._id === value);
                    if (tmp) {
                      setPerson(tmp[0]);
                    }
                  }}
                  style={{ width: "100%" }}
                  placeholder="Шүүгч сонгох"
                  options={options}
                />
              </Form.Item>

              <Form.Item>
                <Row>
                  <Col style={{ textAlign: "center" }}>
                    <Button
                      // loading={loading}
                      htmlType="submit"
                    >
                      Нэмэх
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <RefereeModal
        isVisible={isVisible}
        data={person}
        onCancel={() => {
          setIsVisible(false);
        }}
      />
    </Layout>
  );
};

export default Referee;
