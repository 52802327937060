import { Button, notification, Popconfirm, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { apiSportFederation } from "src/api";
import { SteppeHeader } from "src/components";
import CreateSportFederationModal from "./components/createFederationModal";
import { Image } from "antd";
import SportFederationDetail from "./SportFederationDetail";
import { useForm } from "antd/lib/form/Form";

const SportFederationsList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDetailVisible, setIsDetailVisible] = useState(false);

  const [form] = useForm();
  const [activeRow, setActiveRow] = useState<any[]>([]);
  const handleRow = (e: any) => {
    setActiveRow(e);
  };
  useEffect(() => {
    form.resetFields();
  }, [activeRow, form]);

  const { data, isLoading, refetch } = useQuery("sportFederations", () => {
    return apiSportFederation.find();
  });

  const onCreate = async (values) => {
    const result = await apiSportFederation.create(values);

    if (result?.statusCode === 0) {
      notification.success({ message: "Амжилттай үүсгэлээ." });
      setIsModalVisible(false);
      refetch();
    } else {
      notification.error({ message: result?.error });
    }
  };

  const onUpdate = async (values) => {
    const result = await apiSportFederation?.updateFederation(
      values,
      activeRow?.["_id"]
    );
    if (result?.status === "success") {
      notification.success({ message: "Амжилттай үүсгэлээ." });
      setIsDetailVisible(false);
      refetch();
    } else {
      notification.error({ message: "Алдаа гарлаа." });
    }
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Нэр",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Лого",
      key: "logoUrl",
      dataIndex: "logoUrl",
      render: (row: any, __: any, index: number) => {
        return <Image width={100} src={row} />;
      },
    },
    {
      title: "Үйлдэл",
      key: "actions",
      render: (row: any, __: any, index: number) => {
        return (
          <Space direction="horizontal">
            <Button
              onClick={() => {
                // form.resetFields();
                handleRow(row);
                setIsDetailVisible(true);
              }}
            >
              Засварлах
            </Button>
            <Popconfirm
              title="Устгахдаа итгэлтэй байна уу?"
              onConfirm={async () => {
                const result = await apiSportFederation.delete(row._id);

                if (result?.acknowledged) {
                  notification.success({ message: "Амжилттай устгагдлаа." });
                } else {
                  notification.error({ message: result?.error });
                }
                refetch();
              }}
              cancelText="Цуцлах"
              okText="Устгах"
              okButtonProps={{ danger: true }}
            >
              <Button type="primary" danger>
                Устгах
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <SteppeHeader
        title="Спорт холбоод"
        onCreateClick={() => {
          setIsModalVisible(true);
        }}
      />
      <Table
        rowKey="_id"
        columns={columns}
        dataSource={data?.data}
        scroll={{ x: 1000 }}
        loading={isLoading}
        pagination={{
          total: data?.pagination?.total,
        }}
      />
      <CreateSportFederationModal
        isVisible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        onSubmit={onCreate}
      />

      <SportFederationDetail
        data={activeRow}
        form={form}
        isVisible={isDetailVisible}
        onCancel={() => {
          setIsDetailVisible(false);
        }}
        onUpdate={onUpdate}
      />
    </>
  );
};

export default SportFederationsList;
