import { Link, useParams } from "react-router-dom";
import AthleteList from "./components/AthleteList";
import UserList from "./components/UserList";
import { Button, Form, Input, PageHeader, Row, Spin, notification } from "antd";
import apiOrganization from "src/api/apiOrganization";
import { useQuery } from "react-query";

const OrganizationDetail = () => {
  const { _id } = useParams();

  const onFinish = async (values) => {
    const result = await apiOrganization.updateOrganization(values, _id);


    if (result?.status === "success") {
      notification.success({ message: "Амжилттай хадгаллаа." });
    } else {
      notification.error({ message: result?.error });
    }
  };

  const data = useQuery("organizationsdata", () => {
    return apiOrganization.getOrganization(_id);
  });

  return (
    <>
      <Link to={`/organizations`}>
        <Button>Буцах</Button>
      </Link>

      <PageHeader title={"Байгууллагын мэдээллийг шинэчлэх"} />

      {!data?.data?.data?.name ? (
        <Row justify="center" align="middle">
          <Spin />
        </Row>
      ) : (
        <Form
          name="basic"
          initialValues={{ remember: true, name: data?.data?.data.name }}
          onFinish={onFinish}
          autoComplete="off"
          style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto" }}
        >
          <Form.Item
            label="Нэр"
            name="name"
            rules={[
              {
                required: true,
                message: "Заавал бөглөнө үү.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Хадгалах
            </Button>
          </Form.Item>
        </Form>
      )}

      <UserList organizationId={_id} />
      {/* <CoachList organizationId={_id} /> */}
      <AthleteList organizationId={_id} />
    </>
  );
};

export default OrganizationDetail;
