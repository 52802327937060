import * as React from "react";
import {
  Button,
  Card,
  Col,
  Layout,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Table,
} from "antd";
import { useQuery } from "react-query";
import apiAssign from "src/api/apiAssign";
let logo = require("../../../../assets/logo.png");

const Assign = () => {
  // const [options, setOptions] = React.useState<SelectProps>(null);
  const [person, setPerson] = React.useState(null);
  const { data, isLoading, refetch } = useQuery("press", () => {
    return apiAssign.getPress();
  });
  const updatePress = async (id: any, role: any) => {
    const result = await apiAssign.updatePress(id, role);
    console.log(result)
    if (result?.status === "success") {
      notification.success({ message: "Амжилттай." });
      refetch();
    } else {
      notification.error({ message: result?.error });
    }
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Овог Нэр",
      key: "index",
      render: (row: any) => {
        return <div>{row.lastName[0] + ". " + row.firstName}</div>;
      },
    },
    {
      title: "Сувгийн Нэр",
      key: "index",
      render: (row: any) => {
        return <div>{row.channelName}</div>;
      },
    },
    {
      title: "Үүрэг",
      key: "index",
      render: (row: any) => {
        return (
          <>
            <Row align="middle" className="min-w-max">
              <Select
                onChange={(label) => {
                  updatePress(row._id, { role: label });
                }}
                allowClear
                style={{ width: "100%" }}
                placeholder="Үүрэг"
                options={[
                  {
                    label: "Зурагчин",
                    value: "Зурагчин",
                  },
                  {
                    label: "Сэтгүүлч",
                    value: "Сэтгүүлч",
                  },
                ]}
                defaultValue={
                  row.role
                    ? {
                        label: row.role,
                        value: row._id,
                      }
                    : null
                }
              />
            </Row>
          </>
        );
      },
    },
    {
      title: "Үйлдэл",
      key: "index",
      render: (row: any, __: any, index: number) => {
        return (
          <>
            <Row align="middle" className="min-w-max">
              <Col className="mr-[1rem]">
                <Button onClick={() => setPerson(row)}>Дэлгэрэнгүй</Button>
              </Col>
              <Col>
                <Popconfirm
                  title="Та мантад хэвлэл гэж байна?"
                  disabled={true}
                  cancelText="Цуцлах"
                  okText="Тийм"
                  okButtonProps={{ danger: true }}
                >
                  <Button type="primary">Хэвлэх</Button>
                </Popconfirm>
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  return (
    <Layout className="layout">
      <PageHeader title={"Эвентийн хэвлэл мэдээллүүд"} />
      <Row justify="space-between">
        <Col>
          <Table
            rowKey="_id"
            columns={columns}
            loading={isLoading}
            scroll={{ x: 1000 }}
            dataSource={data}
            // pagination={{
            //   total: data?.pagination?.total,
            // }}
          />
        </Col>
        <Col
          style={{
            marginRight: "auto",
            marginLeft: "auto",
            marginBottom: 20,
          }}
        >
          <Card
            title="Дэлгэрэнгүй мэдээлэл"
            style={{
              width: 400,
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <div className="grid grid-cols-2 mb-[2rem] break-all">
              <div className="col-span-2 max-w-[120px] max-h-[150px] overflo-hidden mx-auto mb-[1rem]">
                <img src={logo} alt="Logo" className="mx-auto" />
              </div>

              <p className="font-[700] italic">Ажилтны зураг:</p>
              <img
                src={person?.avatarUrl}
                alt={person?.avatarUrl}
                className="ml-0"
                width={100}
                height={100}
              />
              {/* <p className="font-[700] italic">Ажилтны үнэмлэх:</p>
              <img
                src={person?.cardUrl}
                alt={person?.cardImgUrl}
                className="ml-0"
                width={100}
                height={100}
              /> */}
              <p className="font-[700] italic">Овог, нэр:</p>
              <p>{person && person?.lastName + " " + person?.firstName}</p>
              <p className="font-[700] italic">Нас:</p>
              <p>{person?.age}</p>
              {/* <p className="font-[700] italic">Регистрийн дугаар:</p>
              <p>УХ02011631</p> */}
              <p className="font-[700] italic">Хүйс:</p>
              <p>{person && (person?.gender === 1 ? "Эмэгтэй" : "Эрэгтэй")}</p>
              <p className="font-[700] italic">Утасны дугаар:</p>
              <p>{person?.phoneNumber}</p>
              <p className="font-[700] italic">И-мэйл хаяг:</p>
              <p>{person?.email}</p>
              <p className="font-[700] italic">Харъяа байгууллага:</p>
              <p>{person?.organization}</p>
              <p className="font-[700] italic">Сувгийн нэр:</p>
              <p>{person?.channelName}</p>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

export default Assign;
