import "./App.less";
import "./App.css";
import { routesAdmin } from "./config/routes";
import { useAuth } from "./hooks";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { DefaultLayout } from "./layouts";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthConsumer, AuthProvider } from "./contexts/AuthContext";
import { NotFound } from "./pages";

const queryClient = new QueryClient();

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}: any) => {
  const { user } = useAuth();

  //   return user ? (
  //     Layout ? (
  //       <Layout>
  //         <Component />
  //       </Layout>
  //     ) : rest?.noLayout ? (
  //       <Component />
  //     ) : (
  //       <DefaultLayout {...rest}>
  //         <Component {...rest} />
  //       </DefaultLayout>
  //     )
  //   ) : (
  //     <Navigate to={"/signin"} />
  //   );

  return (
    user &&
    (user?.claims?.metadata?.role.includes("admin") ? (
      Layout ? (
        <Layout>
          <Component />
        </Layout>
      ) : rest?.noLayout ? (
        <Component />
      ) : (
        <DefaultLayout {...rest}>
          <Component {...rest} />
        </DefaultLayout>
      )
    ) : user?.claims?.metadata?.role.includes("judge") ? (
      //   <Redirect to={"/403"} />
      <>403</>
    ) : (
      <DefaultLayout {...rest}>
        <Component {...rest} />
      </DefaultLayout>
      // <Navigate to={"/signin"} />
      // <Redirect to={"/signin"} />
    ))
  );
};

const PublicRoute = ({ component: Component, ...rest }: any) => {
  // We don't have any public route. I guess

  return <Component {...rest} />;
};

function RequireAuth({ children, redirectTo }) {
  const { user } = useAuth();
  return user ? children : <Navigate to={redirectTo} />;
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <AuthConsumer>
            {({ loaded }) =>
              loaded && (
                <Routes>
                  {routesAdmin?.map((route) => {
                    return (
                      <Route
                        key={route.key}
                        path={route.path}
                        element={
                          route.public ? (
                            <PublicRoute {...route} />
                          ) : (
                            <RequireAuth redirectTo={"/signin"}>
                              <PrivateRoute {...route} />
                            </RequireAuth>
                          )
                        }
                      />
                    );
                  })}
                  <Route path={"/404"} element={<NotFound />} />
                </Routes>
              )
            }
          </AuthConsumer>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
