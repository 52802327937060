import { Button, Form, Input, Modal, Select } from "antd";
import { useQuery } from "react-query";
import { apiSportFederation } from "src/api";

const CreateSportModal = (props) => {
    const { isVisible, onCancel, onSubmit } = props;

    const { data } = useQuery(
        "sportFederations",
        () => {
            return apiSportFederation.find();
        }
    );

    const onFinish = async (values) => {
        onSubmit(values);
    };

    return (
        <Modal
            title="Спорт үүсгэх"
            key={"create_modal"}
            footer={false}
            visible={isVisible}
            onCancel={onCancel}
        >
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="Нэр"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Заавал бөглөнө үү.",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Холбоо"
                    name="federation"
                    rules={[
                        {
                            required: true,
                            message: "Заавал бөглөнө үү.",
                        },
                    ]}
                >
                    <Select>
                        {data?.data.map((el) => (
                            <Select.Option value={el._id}>
                                {el.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Үүсгэх
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateSportModal;
