import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  SelectProps,
  Upload,
  message,
  notification,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { apiEvent, apiSport } from "src/api";
import apiJudge from "src/api/apiJudge";
import { UploadOutlined } from "@ant-design/icons";

const CreateJudgeModal = (props) => {
  const { isVisible, onCancel, form, refetchList } = props;

  const onFinish = async (values) => {
    setLoading(true);
    let tmp = letterF + letterS + registration;
    values.registrationNumber = tmp;

    values.sportDetail = {
      sport: values.sport,
      // sportDegree: values.sportDegree,
      // title: values.title,
    };

    delete values.sport;
    delete values.sportDegree;
    delete values.title;

    const result = await apiJudge.create(values);

    if (result?.statusCode === 0) {
      setLoading(false);
      setFile1(null);
      notification.success({ message: "Амжилттай үүсгэлээ." });
      onCancel(true);
      refetchList();
    } else {
      setLoading(false);
      notification.error({ message: result?.message });
    }
    form.resetFields();
  };

  const [loading, setLoading] = useState(false);

  const [letterF, setLetterF] = useState("");
  const firstLetter = useRef();
  const secondLetter = useRef();
  const [letterS, setLetterS] = useState("");
  const [file1, setFile1] = useState(false);
  const [registration, setRegistration] = useState();

  const onChange = () => {
    form.setFieldsValue({
      username: form.getFieldValue("email"),
    });
  };

  const onChangeFL = (value) => {
    setLetterF(value);
  };

  const onChangeSL = (value) => {
    setLetterS(value);
  };

  const onChangeNumber = (value) => {
    let tmp = value.target.value.split("").slice(0, 8);
    setRegistration(tmp.join(""));
  };

  const { data, isLoading } = useQuery("sports", () => {
    return apiSport.find();
  });

  const options: SelectProps["options"] = [];

  if (!isLoading) {
    data?.data.map((e) => {
      options.push({ label: e.name, value: e._id });

      return null; //TODO fix
    });
  }

  const dict = "абвгдеёжзийклмноөпрстуүфхцчшщъыьэюя".toUpperCase().split("");

  const isImage = (file: RcFile) => {
    const FILE_SIZE = 2 * 1024 * 1024;
    if (file.size > FILE_SIZE) {
      message.error("Зургийн хэмжээ 2мегабайт-с ихгүй байх шаардлагатай!");
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Та зөвхөн JPG/PNG форматтай зураг оруулах боломжтой!");
    }
    setFile1(isJpgOrPng);
    return !isJpgOrPng;
  };

  const handleImage = async (e: any) => {
    if (e.fileList.length === 0) {
      form.setFieldsValue({
        avatarUrl: "",
      });
      setLoading(false);
      // setFile1(false);
    } else {
      setLoading(true);
      let result: any;
      result = await apiEvent.apiUpload(e.file, "image");

      if (result.success === true) {
        e.file = result.data;
        form.setFieldsValue({
          avatarUrl: result.data,
        });
        setLoading(false);
      } else {
        // setLoading(false);
        console.error("error: ", result.e);
      }
    }
  };

  return (
    <Modal
      title="Шүүгч үүсгэх"
      key={"create_modal"}
      width={800}
      footer={false}
      visible={isVisible}
      onCancel={onCancel}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item label="Нэвтрэх нэр" hidden={true} name="username">
          <Input />
        </Form.Item>

        <Form.Item
          label="Цээж зураг"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <Upload
            listType="picture"
            onChange={handleImage}
            beforeUpload={isImage}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Зураг оруулах</Button>
          </Upload>
        </Form.Item>

        <Form.Item name="avatarUrl" hidden={true}>
          {<Input hidden={false} />}
        </Form.Item>

        <Form.Item label="Овог, нэр" style={{ marginBottom: 0 }}>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Заавал бөглөнө үү.",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
            }}
          >
            <Input placeholder="Овог" />
          </Form.Item>
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "Заавал бөглөнө үү.",
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input placeholder="Нэр" />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Хүйс"
          name="gender"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Хүйс"
            // onChange={handleChange}
          >
            <Select.Option key="0" value={0}>
              Эрэгтэй
            </Select.Option>
            <Select.Option key="1" value={1}>
              Эмэгтэй
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Төрсөн өдөр"
          name="birthday"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <DatePicker format="YYYY-MM-DD" placeholder="Төрсөн өдөр" />
        </Form.Item>

        <Form.Item
          label="Утасны дугаар"
          name="phoneNumber"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input type="number" placeholder="Утасны дугаар" />
        </Form.Item>

        <Form.Item label="Регистрийн дугаар" className="mb-0">
          <Input.Group compact>
            <Select ref={firstLetter} onChange={onChangeFL} placeholder="A">
              {dict.map((e: any) => {
                return (
                  <Select.Option key={e} value={e}>
                    {e}
                  </Select.Option>
                );
              })}
            </Select>
            <Select ref={secondLetter} onChange={onChangeSL} placeholder="A">
              {dict.map((e: any) => {
                return (
                  <Select.Option key={e} value={e}>
                    {e}
                  </Select.Option>
                );
              })}
            </Select>

            <Form.Item style={{ marginBottom: 0 }}>
              <Input
                onChange={(value) => {
                  onChangeNumber(value);
                }}
                value={registration}
                placeholder="12341234"
                type="number"
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item
          hidden={true}
          style={{ marginBottom: 0 }}
          name="registrationNumber"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="И-мэйл"
          name="email"
          rules={[
            {
              required: false,
              message: "Хүчингүй и-мэйл байна!",
              type: "email",
            },
          ]}
        >
          <Input onChange={onChange} placeholder="И-мэйл хаяг" />
        </Form.Item>

        <Form.Item hidden={true} name="sportDetail">
          <Select mode="multiple"></Select>
        </Form.Item>

        <Form.Item label="Спортын дэлгэрэнгүй">
          <Input.Group>
            <Row gutter={8}>
              {/* <Col span={8}>
                <Form.Item
                  name="sportDegree"
                  rules={[
                    {
                      required: true,
                      message: "Заавал сонгоно үү.",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    // style={{ width: "100%" }}
                    placeholder="Спортын зэрэг"
                    options={[
                      {
                        value: "1-р зэрэг",
                        label: "1-р зэрэг",
                      },
                      {
                        value: "2-р зэрэг",
                        label: "2-р зэрэг",
                      },
                      {
                        value: "3-р зэрэг",
                        label: "3-р зэрэг",
                      },
                      {
                        value: "Зэрэггүй",
                        label: "Зэрэггүй",
                      },
                    ]}
                  />
                </Form.Item>
              </Col> */}
              <Col span={8}>
                <Form.Item
                  name="sport"
                  style={{ marginBottom: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "Заавал бөглөнө үү.",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    // style={{ width: "100%" }}
                    placeholder="Хариуцаж буй спорт"
                    options={options}
                    style={{ marginBottom: 0 }}
                  />
                </Form.Item>
              </Col>
              {/* <Col span={8}>
                <Form.Item name="title">
                  <Select
                    allowClear
                    // style={{ width: "100%" }}
                    placeholder="Спортын зэрэг"
                    options={[
                      {
                        value: "Спортын Дэд Mастер",
                        label: "Спортын Дэд Mастер",
                      },
                      {
                        value: "Спортын Mастер",
                        label: "Спортын Mастер",
                      },
                      {
                        value: "Олон Улсын Хэмжээний Mастер",
                        label: "Олон Улсын Хэмжээний Mастер",
                      },
                      {
                        value: "Цолгүй",
                        label: "Цолгүй",
                      },
                    ]}
                  />
                </Form.Item>
              </Col> */}
            </Row>
          </Input.Group>
        </Form.Item>

        <Form.Item
          name="degree"
          label="Шүүгчийн зэрэг"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <Select
            allowClear
            // style={{ width: "100%" }}
            placeholder="Шүүгчийн зэрэг"
            options={[
              {
                value: "Шүүгч",
                label: "Шүүгч",
              },
              {
                value: "Улсын Шүүгч",
                label: "Улсын Шүүгч",
              },
              {
                value: "Олон Улсын Шүүгч",
                label: "Олон Улсын Шүүгч",
              },
            ]}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            disabled={!file1}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Үүсгэх
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateJudgeModal;
