import { Button, Form, Input, Modal, Select } from "antd";
import { useState } from "react";

const CreateUserModal = (props) => {
  const { isVisible, onSubmit, onCancel, loading } = props;

  const onFinish = async (values) => {
    onSubmit(values);
    form.resetFields();
  };

  const [form] = Form.useForm();

  const onChange = () => {
    form.setFieldsValue({
      username: form.getFieldValue("email"),
    });
  };

  return (
    <Modal
      title="Хэрэглэгч үүсгэх"
      key={"create_modal"}
      footer={false}
      visible={isVisible}
      onCancel={onCancel}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item label="Нэвтрэх нэр" hidden={true} name="username">
          <Input />
        </Form.Item>

        <Form.Item label="Овог, нэр" style={{ marginBottom: 0 }}>
          <Form.Item
            name="lastName"
            rules={[{ required: false }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
            }}
          >
            <Input placeholder="Овог" />
          </Form.Item>
          <Form.Item
            name="firstName"
            rules={[{ required: false }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input placeholder="Нэр" />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Утасны дугаар"
          name="phone"
          rules={[{ required: true }]}
        >
          <Input placeholder="Утасны дугаар" type="number" />
        </Form.Item>

        <Form.Item
          label="И-мэйл"
          name="email"
          rules={[
            {
              required: true,
              message: "Хүчингүй и-мэйл байна!",
              type: "email",
            },
          ]}
        >
          <Input onChange={onChange} placeholder="И-мэйл хаяг" />
        </Form.Item>

        <Form.Item
          label="Үүрэг"
          name="role"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <Select
            // defaultValue="manager"
            placeholder="Үүрэг"
            style={{ width: 120 }}
            // onChange={handleChange}
            options={[
              {
                value: "manager",
                key: "manager",
                label: "Mенежер",
              },
            ]}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button loading={loading} type="primary" htmlType="submit">
            Үүсгэх
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUserModal;
