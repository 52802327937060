import { Button, Form, Input, Modal, Select } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const CreateUserModal = (props) => {
  const { isVisible, onCancel, onSubmit } = props;

  const onFinish = async (values) => {
    onSubmit(values);
  };

  return (
    <Modal
      title="Хэрэглэгч үүсгэх"
      key={"create_modal"}
      footer={false}
      visible={isVisible}
      onCancel={onCancel}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Нэвтрэх нэр"
          name="username"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="И-мэйл"
          name="email"
          rules={[
            {
              required: true,
              message: "Хүчингүй и-мэйл байна!",
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Үүрэг"
          name="role"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <Select
            defaultValue="manager"
            style={{ width: 120 }}
            // onChange={handleChange}
            options={[
              {
                value: "manager",
                label: "Mенежер",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Нууц үг"
          name="password"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <Input.Password
            placeholder="Хэрэглэгчийн нууц үг"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Үүсгэх
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUserModal;
