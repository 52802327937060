import {
  Button,
  Col,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { apiEvent } from "src/api";
import CreateCompetitionModal from "../components/createCompetitionModal";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import UpdateCompetitionsModal from "../components/UpdateCompetitionsModal";

const CompetitionsPage = () => {
  const { eventId } = useParams();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data, isLoading, refetch } = useQuery(
    ["competitions", eventId],
    async () => {
      return (await apiEvent.competitions(eventId)).find();
    }
  );

  const [form] = useForm();
  const [activeRow, setActiveRow] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [isCategoryVisible, setIsCategoryVisible] = useState(false);

  const handleRow = (e: any) => {
    setActiveRow(e);
  };

  const handleDelete = async (e: any) => {
    const result = await apiEvent.deleteCompetition(eventId, e.id);
    if (result?.statusCode === 0) {
      notification.success({ message: "Амжилттай устгалаа." });
    } else {
      notification.error({ message: result?.error });
    }
    refetch();
  };

  useEffect(() => {
    form.resetFields();
  }, [activeRow, form]);

  const onUpdate = async (values) => {
    setLoading(true);
    const result = await apiEvent.updateCompetition(
      eventId,
      activeRow?.["_id"],
      values
    );

    if (result?.status === 200) {
      setLoading(false);
      notification.success({ message: "Амжилттай хадгаллаа." });
      setIsCategoryVisible(false);
      refetch();
    } else {
      setLoading(false);
      notification.error({ message: result?.error });
    }
  };

  const onCompetitionCreate = async (values) => {
    setLoading(true);
    // console.log(values);
    const result = await apiEvent.createCompetition(eventId, values);
    if (result?.statusCode === 0) {
      setLoading(false);
      notification.success({ message: "Амжилттай үүсгэлээ." });
      setIsModalVisible(false);
      refetch();
    } else {
      setLoading(false);
      notification.error({ message: result?.error });
    }
    refetch();
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Спорт",
      key: "sport",
      dataIndex: ["sport", "name"],
    },
    {
      title: "Нэр",
      key: "name",
      dataIndex: "name",
      innerWidth: 200,
      outerWidth: 200,
    },
    {
      title: "Эхлэх хугацаа",
      key: "startAt",
      dataIndex: "startAt",
      render: (row: any, __: any, index: number) => {
        return moment(row).format("lll");
      },
    },
    {
      title: "Дуусах хугацаа",
      key: "endAt",
      dataIndex: "endAt",
      render: (row: any, __: any, index: number) => {
        return moment(row).format("lll");
      },
    },
    {
      title: "Ангиллууд",
      key: "categories",
      render: (row: any, __: any, index: number) => {
        // console.log(row.categories);
        // return row.categories?.length;?
        return (
          <Link to={`/regsys/events/${row.event}/competitions/${row._id}`}>
            <Button type="primary">{row.categories?.length ?? 0}</Button>
          </Link>
        );
      },
    },
    {
      title: "Бүртгэл (Төлөгдсөн)",
      key: "registrations",
      render: (row: any, __: any, index: number) => {
        let success = 0;
        row.registrations.map((e) => {
          e?.payment.isPaid ? (success = success + 1) : console.log();

          return null; //  TODO fix
        });

        return (
          <Link
            to={`/regsys/events/${row.event}/competitions/${row._id}/registrations`}
          >
            <Button type="primary">
              {success + " / " + row.registrations?.length}
            </Button>
          </Link>
        );
      },
    },
    {
      title: "Үйлдэл",
      key: "actions",
      render: (row: any, __: any, index: number) => {
        return (
          <Row align="middle" className="min-w-max">
            <Button
              className="mr-[1rem]"
              onClick={() => {
                handleRow(row);
                setIsCategoryVisible(true);
              }}
            >
              Засварлах
            </Button>
            <Popconfirm
              title="Устгахдаа итгэлтэй байна уу?"
              onConfirm={() => {
                handleDelete(row);
              }}
              cancelText="Цуцлах"
              okText="Устгах"
              okButtonProps={{ danger: true }}
            >
              <Button type="primary" danger>
                Устгах
              </Button>
            </Popconfirm>
          </Row>
        );
      },
    },
  ];

  return (
    <>
      <Link to={`/regsys/events`}>
        <Button>Буцах</Button>
      </Link>

      <Row justify="space-between" align="middle">
        <Col>
          <PageHeader title="Тэмцээнүүд" />
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            Шинээр үүсгэх
          </Button>
        </Col>
      </Row>

      <Table
        rowKey="_id"
        columns={columns}
        scroll={{ x: 1300 }}
        dataSource={data?.data}
        loading={isLoading}
        pagination={{
          total: data?.pagination?.total,
        }}
      />

      {isCategoryVisible && (
        <UpdateCompetitionsModal
          data={activeRow}
          form={form}
          loading={loading}
          isVisible={isCategoryVisible}
          onCancel={() => {
            setIsCategoryVisible(false);
            form.resetFields();
          }}
          onUpdate={onUpdate}
        />
      )}

      <CreateCompetitionModal
        isVisible={isModalVisible}
        loading={loading}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        onSubmit={onCompetitionCreate}
      />
    </>
  );
};

export default CompetitionsPage;
