const config = {
    sso: {
        baseUrl: process.env.REACT_APP_SSO_BASE_URL,
        organizationID: process.env.REACT_APP_SSO_ORGANIZATION_ID,
        projectID: process.env.REACT_APP_SSO_PROJECT_ID,
    },
    api: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
    },
};

export default config;
