import axiosClient from "src/libs/axiosClient";
import api from "./baseApi";

const updateJudge = async (data: any, judgeId: string) => {
  // const res = api(`/admin/judge/${judgeId}`).update(data);
  const res = await axiosClient.patch(`/admin/judge/${judgeId}`, data);
  // console.log(res);
  return res;
};

const printJudge = (data: any) => {
  const api2 = api(`/admin/referee/print`);

  return api2.create(data);
};

const getRegisteredReferee = async (id: string) => {
  const res = api(`/admin/events/${id}/judge`).get();
  return res;
};
const services = {
  updateJudge,
  getRegisteredReferee,
  printJudge,
};
const apiPayment = api("/admin/judge", services);

export default apiPayment;
