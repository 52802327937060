import { Button, Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useState } from "react";
import { Link } from "react-router-dom";
import Organizers from "./Organizers";
import Press from "./Press";
import Referee from "./Referee";

const Assign = () => {
  const [openPage, setOpenPage] = useState(0);

  const tabs = [<Organizers />, <Press />, <Referee />];
  return (
    <Layout className="layout">
      <Link to={`/regsys/events`} className="ml-4 mt-4">
        <Button>Буцах</Button>
      </Link>
      <div className="flex align-middle p-[1rem] gap-[1rem]">
        <button
          className={`${
            openPage === 0 ? "bg-[#080b3a] text-white" : ""
          } p-4`}
          onClick={() => {
            setOpenPage(0);
          }}
        >
          Зохион Байгуулагчид
        </button>
        <button
          className={`${
            openPage === 1 ? "bg-[#080b3a] text-white" : ""
          } p-4`}
          onClick={() => {
            setOpenPage(1);
          }}
        >
          Хэвлэл Mэдээлэл
        </button>
        <button
          className={`${
            openPage === 2 ? "bg-[#080b3a] text-white" : ""
          } p-4`}
          onClick={() => {
            setOpenPage(2);
          }}
        >
          Шүүгч
        </button>
      </div>
      <Content style={{ padding: "0 50px" }}>
        {tabs[openPage]}
        {/* <Organizers /> */}
        {/* {tab()} */}
        {/* {openPage === 0 && <Organizers />}
        {openPage === 1 && <Press />}
        {openPage === 2 && <Referee />} */}
      </Content>
    </Layout>
  );
};

export default Assign;
