import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Upload,
  message,
} from "antd";
import moment from "moment";
import { useQuery } from "react-query";
import { apiEvent, apiSport } from "src/api";
import type { RcFile } from "antd/es/upload/interface";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";

const CreateCompetitionModal = (props) => {
  const { isVisible, onCancel, onSubmit, loading } = props;

  const { data } = useQuery("sports", () => {
    return apiSport.find();
  });

  const [form] = useForm();
  const [file1, setFile1] = useState(false);

  const handlePdf = async (e: any) => {
    let result: any;
    result = await apiEvent.apiUpload(e.file, "pdf");

    if (result.success === true) {
      e.file = result.data;
      form.setFieldsValue({
        pdfUrl: result.data,
      });
    } else {
      console.error("error: ", result.e);
    }
  };

  const isPDF = (file: RcFile) => {
    const isJpgOrPng = file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("Та зөвхөн PDF файл оруулах боломжтой!");
    }
    setFile1(isJpgOrPng);
    return !isJpgOrPng;
  };

  return (
    <Modal
      title="Тэмцээн үүсгэх"
      key={"create_modal"}
      footer={false}
      visible={isVisible}
      onCancel={onCancel}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        autoComplete="off"
      >
        <Form.Item
          label="Спорт"
          name="sportId"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <Select>
            {data?.data?.map((el) => (
              <Select.Option key={el?._id} value={el?._id}>
                {el?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Нэр"
          name="name"
          rules={[
            {
              required: true,
              message: "Заавал бөглөнө үү.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="pdfUrl" hidden={true}>
          {<Input hidden={false} />}
        </Form.Item>

        <Form.Item
          label="Удирдамж"
          rules={[
            {
              required: true,
              message: "Файл оруулна уу.",
            },
          ]}
        >
          <Upload maxCount={1} beforeUpload={isPDF} onChange={handlePdf}>
            <Button icon={<UploadOutlined />}>Файл оруулах</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Бүртгэлийн статус"
          name="isRegisterOpen"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно уу.",
            },
          ]}
          initialValue={true}
        >
          <Select
            defaultValue={true}
            options={[
              {
                value: true,
                label: "Нээлттэй",
              },
              {
                value: false,
                label: "Хаалттай",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Хураамж"
          name="basePrice"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно уу.",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="priceMultiplier"
          name="priceMultiplier"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно уу.",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="priceMaxCount"
          name="priceMaxCount"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно уу.",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label="Эхлэх огноо"
          name="startAt"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно уу.",
            },
          ]}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{
              defaultValue: moment("00:00:00", "HH:mm:ss"),
            }}
          />
        </Form.Item>
        <Form.Item
          label="Дуусах огноо"
          name="endAt"
          rules={[
            {
              required: true,
              message: "Заавал сонгоно уу.",
            },
          ]}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{
              defaultValue: moment("00:00:00", "HH:mm:ss"),
            }}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            loading={loading}
            disabled={!file1}
            type="primary"
            htmlType="submit"
          >
            Үүсгэх
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateCompetitionModal;
