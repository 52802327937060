import { Button, Card, Col, Modal, Row, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import apiOrganization from "src/api/apiOrganization";
import { SteppeHeader } from "src/components";

interface IPros {
  organizationId: string;
}
const AthleteList = (props: IPros) => {
  const { organizationId } = props;
  const columns = [
    {
      title: "#",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return `${index + 1}`;
      },
    },
    {
      title: "Овог",
      key: "lastName",
      dataIndex: "lastName",
    },
    {
      title: "Нэр",
      key: "firstName",
      dataIndex: "firstName",
    },
    {
      title: "Рег №",
      key: "registrationNumber",
      dataIndex: "registrationNumber",
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (row: any, __: any, index: number) => {
        return (
          <Row align="middle" className="min-w-max">
            <Col style={{ marginRight: 20 }}>
              <Button
                onClick={() => {
                  setActiveRow(row);
                  setAthleteModal(true);
                }}
              >
                Дэлгэрэнгүй
              </Button>
            </Col>
          </Row>
        );
      },
    },
  ];

  const { data, isLoading } = useQuery(
    "athletes",
    () => {
      return apiOrganization.getAthletes(organizationId, {});
    }
  );

  const [activeRow, setActiveRow] = useState<any>();
  const [athleteModal, setAthleteModal] = useState(false);

  return (
    <>
      <SteppeHeader title="Тамирчдын жагсаалт" />
      <Table
        rowKey="_id"
        columns={columns}
        dataSource={data?.data}
        loading={isLoading}
        pagination={{
          total: data?.pagination?.total,
        }}
      />

      <Modal
        visible={athleteModal}
        footer={false}
        onCancel={() => {
          setAthleteModal(false);
        }}
        width={600}
      >
        <Card
          title="Тамирчны дэлгэрэнгүй мэдээлэл"
          style={{
            width: 500,
            borderRadius: 10,
            marginTop: 30,
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <div className="grid grid-cols-2 mb-[2rem]">
            <div className="col-span-2 max-w-[120px] max-h-[150px] overflow-hidden mx-auto mb-[1rem]">
              <img src={activeRow?.avatarUrl} alt="Logo" className="mx-auto" />
            </div>
            <p className="font-[700] italic">Oвог, нэр</p>
            <p>{activeRow?.lastName + " " + activeRow?.firstName}</p>
            <p className="font-[700] italic">Төрсөн он:</p>
            <p>{moment(activeRow?.birthday).format("l")}</p>
            <p className="font-[700] italic">Регистрийн дугаар:</p>
            <p>{activeRow?.registrationNumber}</p>
            <p className="font-[700] italic">Хүйс:</p>
            <p>{activeRow?.gender === 1 ? "Эмэгтэй" : "Эрэгтэй"}</p>
            <p className="font-[700] italic">Утасны дугаар:</p>
            <p>{activeRow?.mobile?.value}</p>
            <p className="font-[700] italic">И-мэйл хаяг:</p>
            <p>{activeRow?.email?.value}</p>
            <p className="font-[700] italic">Харъяа байгууллага:</p>
            <div className="flex flex-wrap">
              {activeRow?.organization?.map((e) => {
                return e.manualCreated ? (
                  <p className="border-[1px] border-grey px-[5px] py-[2px] mr-[20px] rounded-lg">
                    {e.name}
                  </p>
                ) : (
                  <p>Байхгүй</p>
                );
              })}
            </div>
            <p className="font-[700] italic">Оролчож буй ангиллууд:</p>
            <div className="flex flex-wrap">
              {activeRow?.categoriesObjs?.map((e) => {
                return (
                  <p className="border-[1px] border-grey px-[5px] py-[2px] mr-[20px] rounded-lg">
                    {e.name}
                  </p>
                );
              })}
            </div>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default AthleteList;
